import GooglePlacesInput from '../GooglePlacesInput/GooglePlacesInput';
import FormInput from '../FormInput/FormInput';
import SelectInput from '../SelectInput/SelectInput';
import styles from './AddressFormSection.module.scss';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';

/**
 * An enumeration representing the province entity.
 * @enum {string}
 * @readonly
 */
const COUNTRY_ENUM = Object.freeze({
  CA: 'Canada',
});

/**
 * An enumeration representing the province entity.
 * @enum {string}
 * @readonly
 */
// const PROVINCE_ENUM = Object.freeze({
//   NL: 'Newfoundland and Labrador (NL)',
//   PE: 'Prince Edward Island (PE)',
//   ON: 'Ontario (ON)',
//   MB: 'Manitoba (MB)',
//   SK: 'Saskatchewan (SK)',
//   AB: 'Alberta (AB)',
//   BC: 'British Columbia (BC)',
//   YK: 'Yukon (YK)',
//   NT: 'Northwest Territories (NT)',
//   NU: 'Nunavut (NU)',
// });
const PROVINCE_ENUM = Object.freeze({
  NL: 'NL',
  PE: 'PE',
  ON: 'ON',
  MB: 'MB',
  SK: 'SK',
  AB: 'AB',
  BC: 'BC',
  YK: 'YK',
  NT: 'NT',
  NU: 'NU',
});

/**
 * An enumeration representing the types of addresses.
 * @enum {string}
 * @readonly
 */
const ADDRESS_TYPES = Object.freeze({
  /** Principal address */
  SHIPPING: 'SHIPPING',
  /** Billing address */
  BILLING: 'BILLING',
});

export default function AddressFormSection({
  sectionTitle,
  address,
  onAddressChange,
  errors,
  setErrors,
  onAddressEdit,
  setAutocompleteValue,
  addressType,
}) {
  const countryOptions = Object.keys(COUNTRY_ENUM).map((key) => ({
    key: key,
    value: COUNTRY_ENUM[key],
  }));

  const provinceOptions = Object.keys(PROVINCE_ENUM).map((key) => ({
    key: key,
    value: PROVINCE_ENUM[key],
  }));

  return (
    <section className={styles.address}>
      <h3>{sectionTitle}</h3>
      <GooglePlacesInput
        address={address}
        handleChangeBillingAddress={onAddressChange}
        setErrors={setErrors}
        errors={errors}
        label="Address"
        ariaLabel="Billing Address line 1"
        onSelectValue={setAutocompleteValue}
        addressType={addressType}
        placeholder=""
        onEditingValue={onAddressEdit}
      />
      <FormInput
        ariaLabel="Billing Address line 2"
        label="Address 2"
        placeholder=""
        value={address.addressLine2}
        name="addressLine2"
        labelColor={'semiDark'}
        inputChangeHandler={onAddressChange}
        onClearError={setErrors}
        error={errors.billingAddress || null}
      />
      <div className={styles.cityCountryContainer}>
        <FormInput
          ariaLabel="City"
          label="City"
          placeholder=""
          value={address.city}
          name="city"
          labelColor={'semiDark'}
          inputChangeHandler={onAddressChange}
          onClearError={setErrors}
          error={errors.billingAddress || null}
        />

        <SelectInput
          label="Country"
          options={countryOptions}
          onChange={onAddressChange}
          value={address.country}
          name="country"
        />
      </div>
      <div className={styles.zipProvinceContainer}>
        <FormInput
          ariaLabel="Postal Code"
          label="Zip/Postal Code"
          placeholder=""
          value={address.postalCode}
          name="postalCode"
          labelColor={'semiDark'}
          inputChangeHandler={onAddressChange}
          onClearError={setErrors}
          error={errors.billingAddress || null}
        />
        <SelectInput
          label="Province"
          options={provinceOptions}
          onChange={onAddressChange}
          value={address.province}
          name="province"
        />
      </div>
      {/* <div className={styles.shippingAddressCheckbox}>
        <CustomCheckbox
          onChangeChecked={() =>
            setIsBillingEqualToShipping(!isBillingEqualToShipping)
          }
          checked={isBillingEqualToShipping}
          variant="semiDark"
        />
        <label>Shipping address is same as the billing address</label>
      </div> */}
    </section>
  );
}
