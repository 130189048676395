/**
 * Video Player that shows the camera live feed.
 *
 * @class
 * @extends React.Component
 */
import React from 'react';
import Hls from 'hls.js';

/**
 * VideoPlayer is a React component that renders a video player for showing live video feed.
 *
 * @class
 * @extends React.Component
 * @property {Object} props - The props object containing the following properties:
 *                            - source: The source URL of the video.
 *                            - width: The width of the video player.
 *                            - height: The height of the video player.
 *                            - muted: A boolean indicating if the video should be muted.
 * @property {Object} state - An empty object as the state of the component.
 * @method constructor - The constructor function that initializes a new instance of VideoPlayer.
 *                       It takes in a props object and calls the super constructor with the props.
 *                       It also initializes an instance of Hls with the source URL from the props.
 * @method componentDidMount - A lifecycle method that is called after the component has been rendered to the DOM.
 *                             It sets up the Hls video player and attaches it to the video element.
 * @method componentDidUpdate - A lifecycle method that is called when the component updates.
 *                             It checks if the source prop has changed and reloads the video player with the new source.
 * @method render - The render method that returns the JSX for rendering the video player.
 *                  It returns a video element with the source, width, height, and muted properties from the props.
 */
export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.hls = new Hls();
    console.log(props);
  }

  state = {};

  // componentDidMount() {
  //     const video = this.player;
  //     const hls = new Hls();
  //     const url = this.props.source;

  //     hls.attachMedia(video);
  //     hls.on(Hls.Events.MEDIA_ATTACHED, function() {
  //       hls.loadSource(url);
  //       hls.on(Hls.Events.MANIFEST_PARSED, function() { video.play(); });
  //     })
  // }

  /**
   * Invoked immediately after updating occurs. This method is a lifecycle
   * method in React. It is invoked immediately after any call to
   * `setState` or `forceUpdate`.
   *
   * @return {void} This function does not return anything.
   */
  componentDidUpdate() {
    const video = this.player;
    const hls = new Hls();
    const url = this.props.source;

    hls.attachMedia(video);
    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
      hls.loadSource(url);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    });
  }

  /**
   * Invoked immediately after the component is mounted.
   * This method is a lifecycle method in React.
   * It is used to fetch the data from the server.
   *
   * @return {void} This function does not return anything.
   */
  componentDidMount() {
    const video = this.player;
    const hls = new Hls();
    const url = this.props.source;

    hls.attachMedia(video);
    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
      hls.loadSource(url);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    });
  }

  render() {
    return (
      <video
        className="videoCanvas border border-info"
        ref={(player) => (this.player = player)}
        autoPlay={true}
        height={this.props.height}
        width={this.props.width}
        poster="/poster2.png"
        muted={this.props.muted}
        controls
      />
    );
  }
}
