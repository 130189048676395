import React, { useState, useRef, useEffect } from 'react';
import styles from './Header.module.scss';
import { useAuth } from '../../context/useAuth';
import { useLocation } from 'react-router-dom';
import { faTimesCircle, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavItem from '../NavItem/NavItem';
import BrandLogo from '../BrandLogo/BrandLogo';
import NotAuthenticatedUserHeader from '../NotAuthenticatedUserHeader/NotAuthenticatedUserHeader';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import useOutsideClick from '../../utils/useOutsideClick';
import AuthenticatedUserHeader from '../AuthenticatedUserHeader/AuthenticatedUserHeader';

/**
 * React functional component that renders the header module.
 *
 * @param {Object} props - The properties object containing the following properties:
 *   @property {Function} handleLogout - The function to handle user logout.
 * @return {JSX.Element} The rendered header component.
 */
export default function Header() {
  const auth = useAuth();
  const modalRef = useRef();
  const togglerRef = useRef();
  const { pathname } = useLocation();
  // const [show, setShow] = useState(false); // State hook to manage the show of the modal components
  const [isCollapsed, setIsCollapsed] = useState(true);

  // Use the custom hook to handle outside click
  useOutsideClick(modalRef, (event) => {
    if (
      !isCollapsed &&
      togglerRef.current &&
      !togglerRef.current.contains(event.target)
    ) {
      setIsCollapsed(true);
    }
  });

  useEffect(() => {
    setIsCollapsed(true);
  }, [pathname]);

  // Function to change the state of the navigation menu when the hamburger icon is clicked
  const handleChangeCollapsed = (e) => {
    e.stopPropagation();
    setIsCollapsed((prevValue) => !prevValue);
  };

  console.log('isCollapsed', isCollapsed);

  return (
    <header className={styles.header}>
      <nav className={styles.navbarContainer}>
        <div className={styles.mobileShow}>
          <BrandLogo />
          <button
            ref={togglerRef}
            onClick={handleChangeCollapsed}
            className={`${styles.navbarToggler} ${
              isCollapsed && styles.collapsed
            }`}
          >
            <FontAwesomeIcon icon={faBars} className={styles.hamburger} />
            <FontAwesomeIcon icon={faTimesCircle} className={styles.close} />
          </button>
        </div>
        <div
          className={`${styles.navLinksContainer} ${
            isCollapsed && styles.collapsed
          }`}
          ref={modalRef}
        >
          <div className={styles.links}>
            <NavItem linkTo="" label="Home" />
            <NavItem linkTo="events" label="Events" />
            <NavItem linkTo="schedule-demo" label="Pricing" />
            <NavItem linkTo="about" label="About Us" />
            <div className={styles.authNLngContainer}>
              {!auth.user ? (
                <NotAuthenticatedUserHeader />
              ) : (
                <AuthenticatedUserHeader />
              )}
              <LanguagePicker />
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
