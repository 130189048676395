import React, { useState } from 'react';
import { Button, Navbar, Row, Col } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { faFaceSadCry } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShareEvent from './ShareEvent';
import { TAB_TYPES } from '../AccountSettings/AccountSettings';

/**
 * The PaymentStatus function represents the status for the payment.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.success - Flag indicating if the payment is successful.
 * @param {string} props.msg - The message to be displayed for the payment status.
 * @param {function} props.handlePreviewEvent - The function to be called when previewing the event.
 * @param {function} props.handleAddToCalendar - The function to be called when adding the event to the calendar.
 * @param {function} props.handleTryAgain - The function to be called when trying to make the payment again.
 * @param {Object} props.eventDTO - The event object containing the details of the event.
 * @return {JSX.Element} The rendered component.
 */
const PaymentStatus = (props) => {
  /**
   * A React Hook that returns an array of two values: the current search
   * parameters and a function to update them.
   *
   * @return {Array} An array containing the current search parameters and a
   * function that can be used to update them. The current search parameters
   * are represented as a URLSearchParams object and the function to update
   * them is a function that takes a URLSearchParams object as its argument.
   */
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * A React Hook that returns an array of two values: the current state of
   * whether the share event button is visible and a function to update this
   * state.
   *
   * @return {Array} An array containing the current state of whether the
   * share event button is visible and a function that can be used to update
   * this state. The current state is represented as a boolean value and the
   * function to update it is a function that takes a boolean value as its
   * argument.
   */
  const [shareEvent, setShareEvent] = useState(false);

  /**
   * A function that handles the preview event functionality.
   *
   * @return {void} This function does not return anything.
   */
  const previewEvent = () => {
    let params = new URLSearchParams();
    setSearchParams(params);
    props.handlePreviewEvent();
  };

  return (
    <div className="d-flex flex-column align-items-center p-5">
      {props.success && (
        <>
          <img src="/order-complete-icon.svg" alt="order completed" />
          <h2 className="mt-5">Your order is complete</h2>
          <h5 className="my-3">Order #235674937154869 </h5>
          <h5>You will receive a confirmation email with order details</h5>
          <Button
            onClick={() => setShareEvent(true)}
            variant="primary"
            className="text-white mb-2 mt-4 col-lg-5 col-12"
          >
            <h5 className="fw-bold mb-0"> Share with friends</h5>
          </Button>
          {shareEvent && (
            <div className="my-4">
              <ShareEvent
                name={props.eventDTO.eventTitle}
                url={window.location.href}
              />
            </div>
          )}
          <Button
            onClick={props.handleAddToCalendar}
            variant="light"
            className=" text-dark border-dark col-lg-5 col-12"
          >
            <h5 className="fw-bold mb-0"> Add to my calendar</h5>
          </Button>
        </>
      )}

      {!props.success && (
        <>
          <FontAwesomeIcon icon={faFaceSadCry} size="4x" />
          <h2 className="mt-5">Your order failed</h2>
          <h5 className="mt-3 text-center">{props.msg}</h5>
          <Button
            onClick={props.handleTryAgain}
            variant="primary"
            className="text-white mb-2 mt-4 w-25"
          >
            Try Again
          </Button>
        </>
      )}

      <Button
        as={Link}
        to={`/account-settings?tabType=${TAB_TYPES.MY_ORDERS}`}
        variant="link"
        className="text-secondary text-decoration-underline mt-3"
      >
        <h5 className="fw-bold text-default">Open my Dashboard</h5>
      </Button>
    </div>
  );
};

export default PaymentStatus;
