import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import styles from './ScheduleDemo.module.scss';
import dollarIcon from '../../assets/dollar.svg';
import profileCheckIcon from '../../assets/check-mark.svg';
import ticketIcon from '../../assets/ticket.svg';
import FormInput from '../../components/FormInput/FormInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import IconTextDemo from '../../components/IconTextDemo/IconTextDemo';
import barcodingIcon from '../../assets/barcoding.svg';
import fraudDetectionIcon from '../../assets/fraudDetection.svg';
import statisticsIcon from '../../assets/statistics.svg';
import frontImg from '../../assets/frame-cellphones.webp';
import computerImg from '../../assets/computer.webp';
import semiCicles from '../../assets/semi-circles.webp';
import elipse103 from '../../assets/ellipse-103.webp';
import MailList from '../../components/MailList/MailList';
/**
 * ScheduleDemo component.
 *
 * @return {React.Element} The rendered ScheduleDemo component.
 */
export default function ScheduleDemo() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleBookDemoClick = () => {
    console.log('handleBookDemoClick');
    // TODO: Implement btn click logic
  };
  const handleCreateEventClick = () => {
    console.log('handleCreateEventClick');
    // TODO: Implement btn click logic
  };
  return (
    <div className={styles.scheduleDemo}>
      <div className={styles.scheduleDemoContainer}>
        <section className={styles.section1}>
          <div className={styles.reasons}>
            <h2>START NOW FOR FREE</h2>
            <ul>
              <li>
                <span className={styles.iconContainer}>
                  <img src={profileCheckIcon} alt="profile check icon" />
                </span>
                <span>Intuitive event creation and management tools</span>
              </li>
              <li>
                <span className={styles.iconContainer}>
                  <img src={ticketIcon} alt="ticket icon" />
                </span>
                <span>
                  Customizing ticket types, pricing, and availability.
                </span>
              </li>
              <li>
                <span className={styles.iconContainer}>
                  <img src={dollarIcon} alt="dollor icon" />
                </span>
                <span>Connect to Stripe get paid sooner</span>
              </li>
            </ul>
          </div>
          <div className={styles.formContainer}>
            <form onSubmit={handleSubmit}>
              <h3>Book a free demo</h3>
              <div className={styles.nameContainer}>
                <FormInput
                  ariaLabel={'First Name'}
                  label={'First Name'}
                  isRequired={true}
                  value={firstName}
                  onInputChange={setFirstName}
                  placeholder={'Your first name...'}
                  variant="small"
                />
                <FormInput
                  ariaLabel={'Last Name'}
                  label={'Last Name'}
                  isRequired={true}
                  value={lastName}
                  onInputChange={setLastName}
                  placeholder={'Your last name...'}
                  variant="small"
                />
              </div>
              <FormInput
                ariaLabel={'Email'}
                label={'Email'}
                isRequired={true}
                value={email}
                onInputChange={setEmail}
                placeholder={'Your email...'}
                type="email"
                variant="small"
              />
              <div className={styles.phoneContainer}>
                <FormInput
                  ariaLabel={'Phone'}
                  label={'Phone'}
                  value={phone}
                  onInputChange={setPhone}
                  placeholder={'(111)-123-1234'}
                  type="tel"
                  variant="small"
                />
              </div>
              <label>
                Message
                <textarea placeholder="Write your message..." />
              </label>
              <div className={styles.btnContainer}>
                <CustomButton
                  title="BOOK MY DEMO"
                  variant="smallText"
                  type="submit"
                />
              </div>
            </form>
          </div>
        </section>
        <section className={styles.section2}>
          <div className={styles.textContainer}>
            <h2>Innovative way to sell advance tickets</h2>
            <p>Branded to you</p>
          </div>
        </section>
        <section className={styles.section3}>
          <div className={styles.card}>
            <div className={styles.secTitle}>
              <h2>SCAN TICKETS WITH ADDED SECURITY</h2>
            </div>
            <div className={styles.content}>
              <img
                className={styles.frontImg}
                src={frontImg}
                alt="girl scanning tickets and two cellphones mockups"
              />
              <div className={styles.barcoding}>
                <IconTextDemo
                  img={barcodingIcon}
                  title="Barcoding scanning"
                  text="Scanning the smartphone screen or printed confirmation of each attendee to quickly flash their record."
                />
              </div>
              <div className={styles.fraudDetection}>
                <IconTextDemo
                  img={fraudDetectionIcon}
                  title="Fraud detection"
                  text="Being sure that attendees cannot use the same ticket to enter the event."
                />
              </div>
              <div className={styles.statistics}>
                <IconTextDemo
                  img={statisticsIcon}
                  title="Real-time statics"
                  text="A real-time overview of how many tickets have been scanned to stay on top of how many guests have arrived and how many can still be expected.
                "
                />
              </div>
            </div>
          </div>
        </section>
        <section className={styles.section4}>
          <h2>A new innovation and experience</h2>
          <div className={styles.content}>
            <div className={styles.textContainer}>
              <h2 className={styles.streaming}>Streaming(Coming soon)</h2>
              <p>Get Ready for a new experience in fall 2024</p>
            </div>
            <div className={styles.imgContainer}>
              <img src={computerImg} alt="computer" />
            </div>
          </div>
        </section>
        <section className={styles.cta}>
          <img
            src={semiCicles}
            alt="semi circles"
            className={styles.semiCircles}
          />
          <img
            src={elipse103}
            alt=" elipse with one woman in"
            className={styles.elispe}
          />

          <div className={styles.content}>
            <h2>INFLUENCERS SELL MORE TICKETS</h2>
            <p>Maximize Your Earnings with Our Affiliate Marketing Program!</p>
            <div className={styles.btnsContainer}>
              <div className={styles.btns}>
                <CustomButton
                  title="Book Demo"
                  variant="smallText"
                  onClick={handleBookDemoClick}
                  isPill={true}
                  colorScheme="white"
                />
                <CustomButton
                  title="Create Event"
                  variant="smallText"
                  isPill={true}
                  onClick={handleCreateEventClick}
                />
              </div>
            </div>
          </div>
        </section>
        <MailList />
      </div>
    </div>
  );
}
