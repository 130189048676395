import { useState, useLayoutEffect } from 'react';
import { SITE_KEY } from '../constants';

/**
 * Returns a custom hook for integrating reCAPTCHA functionality.
 * This hook initializes and manages reCAPTCHA components.
 *
 * @return {Array} An array containing the captcha response and
 * a function to set the captcha response.
 */
const useRecaptcha = () => {
  /**
   * State hook to set the captcha response.
   * @type {string}
   */
  const [captchaResponse, setCaptchaResponsed] = useState('');
  /**
   * Effect hook that initializes reCAPTCHA.
   * It runs after every render, but before the browser has painted the screen.
   * It has the potential to cause a flash of screen, but is necessary to
   * ensure that reCAPTCHA is always rendered.
   */
  useLayoutEffect(() => {
    const initilizeRecaptcha = () => {
      setTimeout(() => {
        try {
          if (window.location.hostname === 'www.ticketwindowux.com') {
            let el = document.getElementById('captchaDiv');
            window.grecaptcha.render(el, {
              sitekey: SITE_KEY,
              callback: function (token) {
                console.log('token: ' + token);
                setCaptchaResponsed(token);
              },
            });
          }
        } catch (error) {
          console.log('captchaError:', error.message);
        }
      }, 100);
    };

    // Call the initialization function.
    initilizeRecaptcha();
  }, []);

  /**
   * Returns the current captcha response and a function to set it.
   *
   * @return {Array<string, Function>} An array containing the current captcha
   * response (string) and a function to set it (Function).
   */
  return [captchaResponse, setCaptchaResponsed];
};

export default useRecaptcha;
