import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 * Error is a React functional component that displays a custom error page with a status code and a message.
 * @param {Object} props - The component props.
 * @param {number} props.code - The status code of the error.
 * @param {string} props.message - The error message to display.
 * @returns {JSX.Element} - A React component that displays an error page with the status code and message.
 */
const Error = (props) => {
  return (
    <div className="homebanner  page-not-found pb-5">
      <div className="ms-auto mt-auto mb-5 pb-5 me-5">
        <h1 className="mb-4">
          {props.code || '404'} | {props.message || 'Page not Found'}
        </h1>
        <Button
          as={Link}
          to="/"
          variant="secondary"
          className="text-white"
          size="lg"
        >
          Back Home
        </Button>
      </div>
    </div>
  );
};

export default Error;
