import styles from './SelectInput.module.scss';
// import { useState } from 'react';

export default function SelectInput({ label, options, onChange, value, name }) {
  //   const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.selectInput}>
      <label>
        {label}
        <select onChange={onChange} value={value} name={name}>
          {options.map((option) => (
            <option key={option.key} value={option.value}>
              {option.value}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
}
