import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

/**
 * The ShareEvent function represents the share event component.
 *
 * @param {Object} props - The props object containing the url and name.
 * @param {string} props.url - The URL of the event.
 * @param {string} props.name - The name of the event.
 * @returns {ReactElement} - The React element representing the share event component.
 */
const ShareEvent = ({ url, name }) => {
  return (
    <div>
      <div className="mb-3">
        <FacebookShareButton
          url={
            'https://www.ticketwindowux.com/preview-event/65c99167d4297b3dd43edf2e'
          }
          quote={'quote to be added'}
          hashtag={['#TicketWindow', '#Event']}
          description={'description'}
        >
          <FacebookIcon size={32} round />
          <span className="ms-2">Share on Facebook</span>
        </FacebookShareButton>
      </div>

      <div className="mb-3">
        <TwitterShareButton
          title={'Upcoming Event on TicketWindow'}
          url={url}
          hashtag={['#TicketWindow', '#Event']}
        >
          <TwitterIcon size={32} round />
          <span className="ms-2">Share on Twitter</span>
        </TwitterShareButton>
      </div>

      <div className="mb-3">
        <WhatsappShareButton
          title={'Upcoming Event on TicketWindow'}
          url={url}
          hashtag={['#TicketWindow', '#Event']}
        >
          <WhatsappIcon size={32} round />
          <span className="ms-2">Share on Whatsapp</span>
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default ShareEvent;
