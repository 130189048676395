/**
 * Function to set an item to local storage.
 * @param {string} k - The key for the item.
 * @param {any} v - The value to be stored.
 */
const setItem = (k, v) => {
  const jsonData = JSON.stringify(v);
  localStorage.setItem(k, jsonData);
};

/**
 * Function to get an item from local storage.
 *
 * @param {string} key - The key of the item to retrieve.
 * @return {*} The value of the item, or null if it does not exist.
 */
const getItem = (key) => {
  let value = localStorage.getItem(key);
  if (value) {
    value = JSON.parse(value);
  }
  return value;
};

/**
 * Removes an item from local storage.
 *
 * @param {string} key - The key of the item to remove.
 * @return {void} This function does not return anything.
 */
const removeItem = (key) => {
  localStorage.removeItem(key);
};

/**
 * Clears the entire local storage.
 *
 * @return {void} This function does not return anything.
 */
const clearStorage = () => {
  localStorage.clear();
};

export { setItem, getItem, removeItem, clearStorage };
