import styles from './CloseAccountPopupContent.module.scss';
// import { useState } from 'react';
import CustomButton from '../CustomButton/CustomButton';
import { useModal } from '../../context/useModal';

export default function CloseAccountPopupContent({
  onConfirmCancel,
  onDiscardCancel,
}) {
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [errors, setErrors] = useState({});
  const { closeModal } = useModal();

  const handleKeepAccount = () => {
    onDiscardCancel();
    closeModal();
  };

  const handleCancelAccount = () => {
    onConfirmCancel(true);
    closeModal();
  };

  return (
    <div className={styles.closeAccountPopupContent}>
      <h2 className={styles.title}>
        Are you sure that you want to cancel your account?
      </h2>
      <p>Do you know if you cancel your account .....</p>
      <div className={styles.formGroup}>
        <CustomButton
          title="Keep my account"
          clickHandler={handleKeepAccount}
        />
        <CustomButton
          title="Close anyway"
          clickHandler={handleCancelAccount}
          colorScheme="outlineWhite"
        />
      </div>
    </div>
  );
}
