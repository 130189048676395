import React, { useState, useEffect, useRef } from 'react';
import { APIProvider, useMapsLibrary } from '@vis.gl/react-google-maps';
import styles from './GooglePlacesInput.module.scss';

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const GooglePlacesInput = ({
  address,
  setErrors,
  errors,
  label,
  ariaLabel,
  onSelectValue,
  addressType,
  placeholder,
  onEditingValue,
}) => {
  const onPlaceSelect = (place) => {
    console.log('place: ', place);
    onSelectValue(place, addressType);
  };

  const handleEdit = () => {
    if (Object.keys(address).length > 0) {
      onEditingValue(addressType);
    }
  };

  const PlaceAutocomplete = ({ onPlaceSelect }) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const places = useMapsLibrary('places');

    useEffect(() => {
      if (!places || !inputRef.current) return;

      const options = {
        fields: ['geometry', 'name', 'formatted_address', 'address_components'],
      };

      setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);
    useEffect(() => {
      if (!placeAutocomplete) return;

      const autoCompleteListener = placeAutocomplete.addListener(
        'place_changed',
        () => {
          onPlaceSelect(placeAutocomplete.getPlace());
        }
      );
      return () => {
        autoCompleteListener.remove();
      };
    }, [onPlaceSelect, placeAutocomplete]);
    return (
      //   <div className="autocomplete-container">
      <div className={styles.googlePlacesInput}>
        <label className={styles.label}>{label}</label>
        <input
          ref={inputRef}
          name="city"
          aria-label={ariaLabel}
          value={address.addressLine1}
          type="text"
          placeholder={placeholder}
          onFocus={handleEdit}
          onChange={() => {}}
          //   onBlur={() => setIsEditing(false)}
        />
      </div>
    );
  };

  return (
    <APIProvider apiKey={API_KEY}>
      <PlaceAutocomplete onPlaceSelect={onPlaceSelect} />
    </APIProvider>
  );
};

export default GooglePlacesInput;
