import styles from './AuthenticationHead.module.scss';
import { useNavigate } from 'react-router-dom';
import { navigateToPromoter } from '../../utils/NavigationUtils';

export default function AuthenticationHead({ isLogin }) {
  const navigate = useNavigate();

  return (
    <>
      <h1 className={styles.registerTitle}>
        {isLogin ? 'Log in' : 'Create account'}
      </h1>
      <div className={styles.linksContainer}>
        <p>
          {isLogin
            ? 'Not registered with us yet? '
            : 'Already have an account? '}
          <button
            type="button"
            onClick={() => navigate(isLogin ? '/register' : '/login')}
          >
            {isLogin ? 'Sign up' : 'Log in'}
          </button>
        </p>
        {!isLogin && (
          <p>
            Are you a promoter?{' '}
            <button type="button" onClick={navigateToPromoter}>
              Click here
            </button>
          </p>
        )}
      </div>
    </>
  );
}
