import { useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * A Map object that serves as a cache for storing the results of asynchronous operations.
 *
 * @type {Map<string, Promise<any>>}
 */
const cache = new Map();

/**
 * Default options object for the useAsync hook.
 *
 * @typedef {Object} DefaultOptions
 * @property {string} cacheKey - The cache key for storing the result of an asynchronous operation.
 * @property {boolean} refetch - A flag indicating whether to refetch the data from the server or
 *   retrieve the data from the cache.
 */
const defaultOptions = {
  cacheKey: '',
  refetch: false,
};

/**
 * useAsync is a custom React hook that provides a way to handle asynchronous operations and manage their state.
 * It takes in an optional default data parameter and returns an object with data, error, and loading properties.
 *
 * @param {any} defaultData - The default data to be used.
 * @return {Object} An object with data, error, and loading properties.
 */
export const useAsync = (defaultData) => {
  const [data, setData] = useState({
    data: defaultData ?? null,
    error: null,
    loading: true,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const run = useCallback(async (asyncFn, options = {}) => {
    try {
      // Merge the default options with the options passed in
      const { cacheKey, refetch } = { ...defaultOptions, ...options };

      const result = { data: null, error: null, loading: false };

      // If we have a cache key and not requesting a new data, then return the cached data
      if (!refetch && cacheKey && cache.has(cacheKey)) {
        const res = cache.get(cacheKey);
        result.data = res;
      } else {
        setData({ ...result, loading: true });
        const res = await asyncFn();
        // console.log("after api call", res);
        result.data = res.data;
        cacheKey && cache.set(cacheKey, res);
      }
      setData(result);
      return result;
    } catch (error) {
      if (error.response.status) {
        // check for api errors
        navigate(location.pathname, {
          replace: true,
          state: { errorStatusCode: error.response.status },
        });
      }
      const result = { data: null, error: error, loading: false };
      setData(result);
      return result;
    }
  }, []);

  return {
    ...data,
    run,
  };
};
