module.exports = {
    redirectUri: "https://eventlinx-api-events.dev-eb7.workers.dev/googlelogin",
    apiBaseUrl: "https://eventlinx-api-events.dev-eb7.workers.dev",
    baseUrl: 'https://eventlinx-api-events.dev-eb7.workers.dev',
    // reactAppSiteKey: "6Le7W5EgAAAAAFbXUO0Ur9oFlf_ic7YeQV-O9xyx" ,
    reactAppSiteKey :"6LeDSGslAAAAAGLZF6uOIiZtWLlRO89nMGcL24yQ",
    reactAppGoogleApiKey: "AIzaSyDBUm2ck2ayHo9P0r-F586nUy1WAtBCXlk",
    reactAppGoogleAccessToken: "ya29.a0AVvZVspSO4c3y5HPL8Hl1pf3Srw8OLllt6RuIb7TO3IiuuTiruV3eUl1Dpon7me-7r56B60IO7TvvSPQ982p2y_9RbgoBNNlnod_OWIxnRX1_8LN2HcV8miUUGgPTLK_s0uTJIGEPuYmk6cuZd_9jw_bnotRaCgYKAZgSARISFQGbdwaIpCFjdaDzxBZ1dmbtHfWWow0163",
    reactAppGoogleCalendarId: "d9f87c00142703bd53aa142a7cf5400176d1110475d9e0936d74046c7ff8f5ea@group.calendar.google.com",
    googleClientId: "964160262873-dsir0eiut7n0bis10b62rqj2efbtfpa7.apps.googleusercontent.com",
    googleOAuthApiKey: "AIzaSyA3WXpBhxqhNX6QL6MFI6qhkOoCdqHEVMM",
    gapiScopes: "https://www.googleapis.com/auth/calendar.events",
    stripePublishKey: "pk_test_dOq1Dn4A7X1B8FH4Ion5aCVe",
    gapiDiscoverDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
};

  