import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ImagePicker from '../../../components/ImagePicker/ImagePicker';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import CustomButton from '../../../components/CustomButton/CustomButton';
import styles from './ContactInfo.module.scss';
import { getUserDetails, updateContactInfo } from '../../../utils/APIUtils';
import validateContactInfo from './contactInfoValidator';
import { useAuth } from '../../../context/useAuth';
import FormInput from '../../../components/FormInput/FormInput';
import SelectInput from '../../../components/SelectInput/SelectInput';
import GooglePlacesInput from '../../../components/GooglePlacesInput/GooglePlacesInput';
import AddressFormSection from '../../../components/AddressFormSection/AddressFormSection';

/**
 * An enumeration representing the province entity.
 * @enum {string}
 * @readonly
 */
const COUNTRY_ENUM = Object.freeze({
  CA: 'Canada',
});

/**
 * An enumeration representing the province entity.
 * @enum {string}
 * @readonly
 */
const PROVINCE_ENUM = Object.freeze({
  NL: 'NL',
  PE: 'PE',
  ON: 'ON',
  MB: 'MB',
  SK: 'SK',
  AB: 'AB',
  BC: 'BC',
  YK: 'YK',
  NT: 'NT',
  NU: 'NU',
});

/**
 * An enumeration representing the types of addresses.
 * @enum {string}
 * @readonly
 */
const ADDRESS_TYPES = Object.freeze({
  /** Principal address */
  SHIPPING: 'SHIPPING',
  /** Billing address */
  BILLING: 'BILLING',
});

/**
 * The ContactInfo function is the main component for handling the contact
 * information of the user.
 *
 * @return {JSX.Element} The JSX element representing the ContactInfo component.
 */
export default function ContactInfo() {
  const navigate = useNavigate();
  const auth = useAuth();
  const emptyAddress = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
  };

  /* 
    TODO: ADD THE UPDATE SUCCESS POPUP
  */

  // refs
  const inputRef = useRef(null);
  const profileImageRefDiv = useRef(null);
  const [image, setImage] = useState(auth.user?.profilePictureUrl || '');
  const [firstName, setFirstName] = useState(auth.user?.firstName);
  const [lastName, setLastName] = useState(auth.user?.lastName || '');
  const [phone, setPhone] = useState(auth.user?.phoneNumber || '');
  const [billingAddress, setBillingAddress] = useState(
    auth.user?.billingAddress || emptyAddress
  );
  const [shippingAddress, setShippingAddress] = useState(
    auth.user?.shippingAddress || emptyAddress
  );
  const [isBillingEqualToShipping, setIsBillingEqualToShipping] =
    useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (auth.user) {
      setImage(auth.user.profilePictureUrl);
      setFirstName(auth.user.firstName);
      setLastName(auth.user.lastName);
      setPhone(auth.user.phoneNumber);
      setBillingAddress(auth.user.billingAddress || emptyAddress);
      setShippingAddress(auth.user.shippingAddress || emptyAddress);
    }
  }, [auth.user]);

  /**
   * Validates the contact information.
   *
   * @return {boolean} Returns true if the contact information is valid, false otherwise.
   */
  const validate = () => {
    // TODO: Still need to validate the addresses, update the validator method
    const userDetails = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phone,
      billingAddress: billingAddress,
      shippingAddress: shippingAddress,
    };
    const error = validateContactInfo(userDetails);
    if (Object.entries(error).length > 0) {
      console.log('errors: ', error);

      setErrors(error);
      return false;
    }
    return true;
  };

  const handleChangeBillingAddress = (e) => {
    const { name, value } = e.target;

    setBillingAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
    if (isBillingEqualToShipping) {
      setShippingAddress(billingAddress);
    }
  };
  const handleChangeShippingAddress = (e) => {
    const { name, value } = e.target;

    setShippingAddress((prevAddress) => ({ ...prevAddress, [name]: value }));
  };

  // Helper function to get address component
  const getAddressComponent = (addressComponents, type) => {
    const component = addressComponents.find((component) =>
      component.types.includes(type)
    );
    return component ? component.short_name : '';
  };

  // Handle address autocomplete
  const setAutocompleteValue = (value, addressType) => {
    console.log('addressType', addressType);
    console.log('value', value);
    const rAddressLine1 = `${getAddressComponent(
      value.address_components,
      'street_number'
    )} ${getAddressComponent(value.address_components, 'route')}`;
    const rAddressLine2 = getAddressComponent(
      value.address_components,
      'subpremise'
    );
    const rCity = getAddressComponent(value.address_components, 'locality');
    const rCountry = getAddressComponent(value.address_components, 'country');
    const rProvince = getAddressComponent(
      value.address_components,
      'administrative_area_level_1'
    );
    const rPostalCode = getAddressComponent(
      value.address_components,
      'postal_code'
    );
    const address = {
      addressLine1: rAddressLine1,
      addressLine2: rAddressLine2,
      city: rCity,
      country: COUNTRY_ENUM[rCountry] || rCountry,
      province: PROVINCE_ENUM[rProvince] || rProvince,
      postalCode: rPostalCode,
    };

    if (addressType === ADDRESS_TYPES.BILLING) {
      setBillingAddress(address);
      if (isBillingEqualToShipping) {
        setShippingAddress(address);
      }
    } else if (addressType === ADDRESS_TYPES.SHIPPING) {
      setShippingAddress(address);
    }
  };

  const onAddressEdit = (type) => {
    if (type === ADDRESS_TYPES.BILLING) {
      setBillingAddress({});
    } else if (type === ADDRESS_TYPES.SHIPPING) {
      setShippingAddress({});
    }
  };

  // Handles the form submission event.
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('auth.user: ', auth.user);

    if (auth.user) {
      // Create user details object
      const userDetails = {
        firstName: firstName,
        lastName: lastName,
        authUserID: auth.user.authUserID,
        additionalInfo: {},
      };

      if (phone) {
        userDetails.phoneNumber = phone;
      }
      if (billingAddress) {
        userDetails.billingAddress = billingAddress;
      }
      if (shippingAddress) {
        userDetails.shippingAddress = shippingAddress;
      }
      if (image) {
        userDetails.profilePictureUrl = image;
      }
      // TODO: Add validation
      if (validate()) {
        // Send the user details to the api
        console.log('userDetails validated: ', userDetails);

        const res = await updateContactInfo(userDetails);
        if (res.status === 200) {
          //show success message
          console.log('res.data: ', res.data);
        } else {
          // show error
          console.log('res.data: ', res.data);
          setErrors((prebErrors) => {
            return {
              ...prebErrors,
              updating: 'Something went wrong, please try again',
            };
          });
        }
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <div className={styles.contactInfo}>
      <div className={styles.contactInfoContainer}>
        <div className={styles.titleContainer}>
          <h2>Contact Information</h2>
          <p>Update your account details anytime</p>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <section className={styles.imgContainer}>
            <h4>Profile picture</h4>
            <div className={styles.tempImage}></div>
            {/* <ImagePicker image={image} onImageChange={handleImageChange} /> */}
          </section>
          <section className={styles.basicInfo}>
            <h3>Basic information</h3>
            <div className={styles.nameContainer}>
              <FormInput
                ariaLabel="first name"
                label="First Name"
                placeholder=""
                value={firstName}
                name="firstName"
                labelColor={'semiDark'}
                isRequired={true}
                onInputChange={setFirstName}
                onClearError={setErrors}
                error={errors.firstName || errors.updating || null}
              />
              <FormInput
                ariaLabel="last name"
                label="Last Name"
                placeholder=""
                value={lastName}
                name="lastName"
                labelColor={'semiDark'}
                isRequired={true}
                onInputChange={setLastName}
                onClearError={setErrors}
                error={errors.lastName || null}
              />
            </div>
            <div className={styles.phoneContainer}>
              <FormInput
                ariaLabel="phone"
                label="Phone"
                placeholder=""
                value={phone}
                name="phone"
                labelColor={'semiDark'}
                onInputChange={setPhone}
                onClearError={setErrors}
                error={errors.phoneNumber || null}
              />
            </div>
          </section>
          <div className={styles.addressContainer}>
            <AddressFormSection
              sectionTitle="Billing Address"
              address={billingAddress}
              onAddressChange={handleChangeBillingAddress}
              errors={errors}
              setErrors={setErrors}
              onAddressEdit={onAddressEdit}
              setAutocompleteValue={setAutocompleteValue}
              addressType={ADDRESS_TYPES.BILLING}
            />
            <div className={styles.shippingAddressCheckbox}>
              <CustomCheckbox
                onChangeChecked={() =>
                  setIsBillingEqualToShipping(!isBillingEqualToShipping)
                }
                checked={isBillingEqualToShipping}
                variant="semiDark"
              />
              <label>Shipping address is same as the billing address</label>
            </div>

            {!isBillingEqualToShipping && (
              <AddressFormSection
                sectionTitle="Shipping Address"
                address={shippingAddress}
                onAddressChange={handleChangeShippingAddress}
                errors={errors}
                setErrors={setErrors}
                onAddressEdit={onAddressEdit}
                setAutocompleteValue={setAutocompleteValue}
                addressType={ADDRESS_TYPES.SHIPPING}
              />
            )}
            <div className={styles.btnContainer}>
              <CustomButton
                type="submit"
                className={styles.submitButton}
                title="Save"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
