import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserDetails, register, verifyemail } from '../../utils/APIUtils';
import useRecaptcha from '../../context/useRecaptcha';
import ToastMessage from '../../shared/ToastMessage';
import FormInput from '../../components/FormInput/FormInput';
import KeepMeSignAndForgot from '../../components/KeepMeSignAndForgot/KeepMeSignAndForgot';
import ConfirmVerificationCode from '../../components/ConfirmVerificationCode/ConfirmVerificationCode';
import AuthenticationHead from '../../components/AuthenticationHead/AuthenticationHead';
import AuthenticationFoot from '../../components/AuthenticationFoot/AuthenticationFoot';
import { useAuth } from '../../context/useAuth';
import styles from './SignUp.module.scss';
import { validate } from '../../utils/authValidationUtils';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import { useModal } from '../../context/useModal';
import TermsAndConditions from '../../components/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';
import { set } from 'lodash';
import CustomButton from '../../components/CustomButton/CustomButton';

export default function SignUp() {
  const [iscodeRequested, setIsCodeRequested] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [rememberUser, setRememberUser] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState({});
  const [emailVerificationCode, setEmailVerificationCode] = useState('');
  const [pendingAuthToken, setPendingAuthToken] = useState('');
  const [toast, setToast] = useState({
    show: false,
    success: false,
    msg: '',
  });
  const [captchaResponse, setCaptchaResponsed] = useRecaptcha();
  const storageUtils = require('../../utils/LocalStorageUtils');

  const navigate = useNavigate();
  const auth = useAuth();
  const modal = useModal();
  const { openModal } = modal;

  /**
   * Resets all the fields in the form.
   *
   * @return {void} This function does not return anything.
   */
  const resetAllFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
  };

  /**
   * Handles the success of user registration.
   *
   * @param {Object} res - The response object containing user data.
   * @return {void}
   */
  const handleRegisterSuccess = (res) => {
    setToast({
      show: true,
      success: true,
      msg: 'User Registered Successfully, Please check your email for verification code',
    });
    setIsCodeRequested(true);
    setEmailVerificationCode('');
    setPendingAuthToken(res.data.pending_authentication_token);
  };

  const handleAuthenticationSuccess = async (registrationData, response) => {
    console.log('response in handleAuthenticationSuccess', response);

    let loggedInUser = { ...response.data };
    // store the user's email in localStorage if remember me is checked
    if (rememberUser) {
      storageUtils.setItem('email', registrationData.email);
    }

    const userInfo = await getUserDetails();

    if (userInfo) {
      // Set user on the auth context
      auth.setUser(userInfo);
    } else {
      // Set user on the auth context
      auth.setUser(loggedInUser);
    }

    // Navigate to events
    setTimeout(() => {
      navigate('/events');
    }, 100);
  };

  /**
   * Handles the error of user registration.
   *
   * @param {Object} err - The error object containing the error message.
   * @return {void}
   */
  const handleRegisterError = (err) => {
    if (!err.error) {
      err.error = 'Something went wrong please try again later';
    }
    setErrors({ auth: err.response.data.message });
    setToast({
      show: true,
      success: false,
      msg: err.response.data.message,
    });

    if (process.env.NODE_ENV !== 'development') {
      window.grecaptcha.reset();
    }
  };

  /**
   * Handles the form submission event.
   *
   * @param {Event} e - The form submission event.
   * @return {void} This function does not return anything.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO:Implement captcha when ready to use
    const errs = validate({
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    });
    if (Object.keys(errs).length === 0) {
      const registerData = {
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
        userType: 'member',
      };
      register(registerData, captchaResponse)
        .then((res) => {
          console.log('REGISTER SUCCESS', res);

          handleRegisterSuccess(res);
        })
        .catch((err) => {
          handleRegisterError(err);
        });
    } else {
      setErrors(errs);
    }
  };

  // TODO:Implement captcha when ready to use
  /**
   * Handles the form submission event for the verification code form.
   *
   * @param {Event} e - The form submission event.
   * @return {void} This function does not return anything.
   */
  const handleSubmitVerificationCode = (e) => {
    e.preventDefault();
    verifyemail(emailVerificationCode, pendingAuthToken)
      .then((res) => {
        const userData = {
          email: email,
          password: password,
          // captchaResponse: captchaResponse
        };
        resetAllFields();
        handleAuthenticationSuccess(userData, res);
      })
      .catch((e) => {
        setEmailVerificationCode('');
        setErrors({
          emailVerification:
            e.response.data.message || 'Error trying to verify email',
        });
        navigate('/login');
        console.log('Error trying to verify email', e);
      });
  };

  const clearErrorWhenTyping = () => {
    setErrors({});
  };

  const toggleRememberUser = () => {
    console.log('rememberUser in toggle: ', rememberUser);
    setRememberUser(!rememberUser);
  };
  const toggleAgreed = () => {
    setAgreed(!agreed);
  };

  return (
    <div className={styles.signUp}>
      {iscodeRequested ? (
        <ConfirmVerificationCode
          handleSubmitVerificationCode={handleSubmitVerificationCode}
          emailVerificationCode={emailVerificationCode}
          setEmailVerificationCode={setEmailVerificationCode}
          errors={errors}
        />
      ) : (
        <div className={styles.signUpCard}>
          <AuthenticationHead isLogin={false} />
          <form onSubmit={handleSubmit}>
            <FormInput
              ariaLabel="first name"
              label="First name"
              type="text"
              value={firstName}
              onInputChange={setFirstName}
              error={errors.firstName}
              onClearError={clearErrorWhenTyping}
            />
            <FormInput
              ariaLabel="last name"
              label="Last name"
              type="text"
              value={lastName}
              onInputChange={setLastName}
              error={errors.lastName}
              onClearError={clearErrorWhenTyping}
            />
            <FormInput
              ariaLabel="username"
              label="Email"
              type="email"
              value={email}
              onInputChange={setEmail}
              error={errors.email}
              onClearError={clearErrorWhenTyping}
            />
            <FormInput
              ariaLabel="password"
              label="Password"
              type="password"
              value={password}
              onInputChange={setPassword}
              minLength={8}
              error={errors.password || errors.auth}
              onClearError={clearErrorWhenTyping}
            />
            <FormInput
              ariaLabel="password confirmation"
              label="Confirm Password"
              type="password"
              value={confirmPassword}
              onInputChange={setConfirmPassword}
              minLength={8}
              error={errors.confirmPassword}
              onClearError={clearErrorWhenTyping}
            />
            <div className={styles.legalContainer}>
              <label className={styles.legalLabel}>
                <CustomCheckbox
                  onChangeChecked={toggleRememberUser}
                  checked={rememberUser}
                />
                <span className={styles.legalText}>Remember me</span>
              </label>
              <label className={styles.legalLabel}>
                <CustomCheckbox
                  onChangeChecked={toggleAgreed}
                  checked={agreed}
                />
                <span className={styles.legalText}>
                  I agree to all the{' '}
                  <button
                    type="button"
                    onClick={() => openModal(<TermsAndConditions />)}
                  >
                    Terms
                  </button>{' '}
                  and{' '}
                  <button
                    type="button"
                    onClick={() => openModal(<PrivacyPolicy />)}
                  >
                    Privacy policy
                  </button>{' '}
                </span>
              </label>
              <p className={styles.legal}>
                {' '}
                By continuing past this page, you agree to the Terms of Use and
                understand that information will be used as described in our
                Privacy Policy.
              </p>
            </div>
            <button className={styles.signUpBtn} type="submit">
              Create account
            </button>
          </form>
          <AuthenticationFoot />
        </div>
      )}
    </div>
  );
}
