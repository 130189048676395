import styles from './MailList.module.scss';
import CustomButton from '../CustomButton/CustomButton';
import { useState } from 'react';

export default function MailList() {
  const [email, setEmail] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from actually submitting
    console.log('Form submitted with email:', email);
    // TODO: Add further actions, like sending the email to the backend
    // and displaying a success message or error message
    setEmail('');
  };
  return (
    <section className={styles.mailListContainer}>
      <h2>Stay up to date with EventLinx</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Enter your email address"
          value={email}
          onChange={setEmail}
        />
        <div className={styles.btnContainer}>
          <CustomButton type={'submit'} title="Subscribe" />
        </div>
      </form>
    </section>
  );
}
