// import { API_KEY, DISCOVERY_DOCS, CLIENT_ID, SCOPES } from "../../../../constants";
// var gapi = window.gapi;
// import { gapi } from "gapi-script";

/**
 * @constant {string}
 * @readonly
 * @description Global constant for the API Key used for the Google API for Authorization and Authentication
 * Calendar API.
 */
const API_KEY = 'AIzaSyBXePWiBD4HRmNv80LGiaCrMcNYPxstvcU';

/**
 * @constant {Array.<string>}
 * @readonly
 * @description Global constant for the Google API Discovery Docs Array.
 * This array contains the discovery doc for the Google Calendar API.
 */
const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
];

/**
 * @constant {string}
 * @readonly
 * @description Global constant for the Client ID used for Google API
 * Authorization and Authentication.
 */
const CLIENT_ID =
  '401342445704-gt6oo36bldnn74av2q6dbten964286o9.apps.googleusercontent.com';

/**
 * @constant {string}
 * @readonly
 * @description Global constant for the Scopes required for accessing the Google Calendar Events API.
 * This constant is used to specify the access level for the Google Calendar API.
 * The access level is set to 'https://www.googleapis.com/auth/calendar.events' which means it allows
 * the application to create, retrieve, and manage events on the user's primary calendar.
 */
const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

/**
 * Initializes the Google API Client and authenticates the user.
 *
 * @param {function} callback - The callback function to be called after the client is initialized.
 * This function is called with a boolean parameter indicating whether the initialization was successful
 * or not.
 * @return {void}
 */
export function initClient(callback) {
  window.gapi.load('client:auth2', () => {
    try {
      window.gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(
          function () {
            // If initilization is successful then call the callback with true
            if (typeof callback === 'function') {
              callback(true);
            }
          },
          function (error) {
            console.log(error);
          }
        );
    } catch (error) {
      // Log any error occur during initialization
      console.log(error);
    }
  });
}

/**
 * This function checks the sign-in status of the user.
 *
 * @return {Promise<boolean>} A promise that resolves to a boolean indicating whether the user is signed in or not.
 * If the promise resolves to true, it means the user is signed in. If the promise resolves to false, it means the user is not signed in.
 * If an error occurs during the process, the promise is rejected with the error.
 */
export const checkSignInStatus = async () => {
  try {
    let status = await window.gapi.auth2.getAuthInstance().isSignedIn.get();
    return status;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Asynchronously signs in to Google.
 *
 * @return {Promise<boolean>} A Promise that resolves to a boolean indicating whether the user was successfully signed in or not.
 * If the Promise resolves to true, it means the user was signed in. If the Promise resolves to false, it means the user was not signed in.
 * If an error occurs during the process, the Promise is rejected with the error.
 */
export const signInToGoogle = async () => {
  try {
    let googleuser = await window.gapi.auth2
      .getAuthInstance()
      .signIn({ prompt: 'consent' });
    if (googleuser) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * Asynchronously signs out the user from Google.
 *
 * @return {Promise<boolean>} A Promise that resolves to a boolean indicating whether the user was successfully signed out or not.
 * If the Promise resolves to true, it means the user was signed out. If the Promise resolves to false, it means the user was not signed out.
 * If an error occurs during the process, the Promise is rejected with the error.
 */
export const signOutFromGoogle = () => {
  try {
    var auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signOut().then(function () {
      auth2.disconnect();
    });
    return true;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Asynchronously retrieves the email of the currently signed in user from Google.
 *
 * @return {Promise<string|null>} A Promise that resolves to a string containing the email of the signed in user.
 * If the Promise resolves to a string, it means the user is signed in and the string contains their email.
 * If the Promise resolves to null, it means the user is not signed in.
 * If an error occurs during the process, the Promise is rejected with the error.
 */
export const getSignedInUserEmail = async () => {
  try {
    let status = await checkSignInStatus();
    if (status) {
      var auth2 = window.gapi.auth2.getAuthInstance();
      var profile = auth2.currentUser.get().getBasicProfile();
      return profile.getEmail();
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * Asynchronously publishes the given event on the user's calendar.
 *
 * @param {Object} event - The event object to be published.
 * @param {string} event.summary - The summary of the event.
 * @param {string} event.location - The location of the event.
 * @param {Object} event.start - The start time of the event.
 * @param {string} event.start.dateTime - The start time of the event in ISO 8601 format.
 * @param {string} event.start.timeZone - The time zone of the start time.
 * @param {Object} event.end - The end time of the event.
 * @param {string} event.end.dateTime - The end time of the event in ISO 8601 format.
 * @param {string} event.end.timeZone - The time zone of the end time.
 * @param {Array<Object>} [event.attendees] - The attendees of the event. Each attendee is an object with a `email` property.
 * @param {Object} [event.reminders] - The reminders for the event.
 * @param {boolean} [event.reminders.useDefault] - Whether to use the default reminders or not.
 * @param {Array<Object>} [event.reminders.overrides] - The reminder overrides. Each override is an object with a `method` and `minutes` property.
 * @return {Promise<Object|null>} A Promise that resolves to an object containing the `htmlLink` property of the created event, or null if the event was not created. If the Promise is rejected, it means an error occurred during the process.
 */
export const publishTheCalenderEvent = async (event) => {
  try {
    return new Promise((resolve, reject) => {
      window.gapi.client.load('calendar', 'v3', () => {
        var request = window.gapi.client.calendar.events.insert({
          calendarId: 'primary',
          resource: event,
        });

        request.execute(function (event) {
          console.log('Event created: ' + event.htmlLink);
          resolve(event);
        });
      });
    });
  } catch (error) {
    console.log(error);
  }
};
