import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Button } from 'react-bootstrap';

/**
 * React functional component that renders a payment element using the Stripe API.
 *
 * @param {Object} props - The props object containing the following properties:
 *    - {Object} data: The data object containing the payment method ID and client
 *      secret.
 *    - {boolean} subscription: Indicates whether the payment is for a subscription.
 * @return {JSX.Element} The JSX element representing the payment element.
 */
export default function PayExistingElement(props) {
  /**
   * Returns the `stripe` object from the `useStripe` hook.
   *
   * @return {Object} The `stripe` object obtained from the `useStripe` hook.
   */
  const stripe = useStripe();
  console.log('Lowest : ', props.data);

  /**
   * This useEffect hook is triggered when the component mounts or updates.
   * It does not accept any dependencies.
   *
   * @return {void} This function does not return anything.
   */
  useEffect(() => {
    const paymentMethodId = props.data.paymentMethodId;
    const clientSecret = props.data.clientSecret;
    const subscription = props.subscription;

    if (!stripe || !clientSecret) {
      return;
    }

    console.log('subscription ', subscription);
    // if(subscription){
    //   const options = {
    //     clientSecret:  clientSecret,
    //   };
    //   const elements = stripe.elements(options);
    //   const {error} = stripe.confirmPayment({
    //     //`Elements` instance that was used to create the Payment Element
    //     elements,
    //     confirmParams: {
    //       return_url: 'http://localhost:3000/home',
    //     }
    //   });

    //   if (error) {
    //     // This point will only be reached if there is an immediate error when
    //     // confirming the payment. Show error to your customer (for example, payment
    //     // details incomplete)
    //     const messageContainer = document.querySelector('#error-message');
    //     messageContainer.textContent = error.message;
    //   } else {
    //     // Your customer will be redirected to your `return_url`. For some payment
    //     // methods like iDEAL, your customer will be redirected to an intermediate
    //     // site first to authorize the payment, then redirected to the `return_url`.
    //   }
    // }else{
    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
      })
      .then(function (result) {
        props.handlePaymentCallback(result);
        // if (result.error) {
        //   // Show error to your customer
        //   alert(result.error.message);
        //   //setMessage(result.error.message);
        // } else {
        //   if (result.paymentIntent.status === 'succeeded') {
        //     // The payment is complete!
        //     alert("success");

        //   }
        // }
      });
    //  }
  }, [stripe]);

  return <div></div>;
}
