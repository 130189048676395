import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './KeepMeSignAndForgot.module.scss';
export default function KeepMeSignAndForgot({ onChangeChecked, rememberUser }) {
  return (
    <div className={styles.keepMeSignAndForgot}>
      <label htmlFor="formBasicCheckbox">
        <input
          onChange={(e) => onChangeChecked(e.target.checked)}
          checked={rememberUser}
          type="checkbox"
          aria-label="checkbox for keep me signed in"
          id="formBasicCheckbox"
        />
        Keep me signed in
      </label>
      <Link to="/forgot-password" className={styles.forgotLink}>
        Forgot Password?
      </Link>
    </div>
  );
}
