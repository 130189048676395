import React, { useState, useEffect } from 'react';
import { faChevronLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAsync } from '../../../context/useAsync';
import {
  cancelOrder,
  getOrderList,
  getFeeDetails,
} from '../../../utils/APIUtils';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import {
  CANCEL_ORDER_TOOLTIP,
  SERVICE_FEE_PERCENT,
  TAX_PERCENTAGE,
  TICKET_TYPE,
  RESTOCKING_FEE,
  APPLICATION_FEE,
  GATEWAY_FEE,
} from '../../../constants';
import Pagination from '../../../shared/Pagination';
import RefundReasonModal from './RefundReasonModal';
import ContactOrganizerModal from './ContactOrganizerModal';
import ViewReceiptModal from './ViewReceiptModal';
import EventListCard from '../../Events/EventListCard';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import styles from './MyOrders.module.scss';

/**
 * Enumeration representing the steps contact organizer.
 * @enum {string}
 * @readonly
 */
const CONTACT_ORGANIZER_STEPS = Object.freeze({
  SHOW_FAQ: 'SHOW_FAQ',
  SHOW_FORM: 'SHOW_FORM',
  SHOW_DETAILS: 'SHOW_DETAILS',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
});

// This function is used to display the user orders.
const queryClient = new QueryClient();
const MyOrders = () => {
  /* 
  ************************************************************************************
       TODO: Continue when the API is ready or the data structure is known.
  ************************************************************************************
  */

  // const { data, loading, error, run } = useAsync(null);
  const [orderList, setOrderList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { loading, error, orders } = useQuery({
    queryKey: ['orders'],
    queryFn: getOrderList,
    // onSuccess: (data) => {
    //   setOrderList(data.patronOrderDetailsDtoList);
    //   setTotalPages(data.totalPages);
    // },
  });

  // useEffect(() => {
  //   // run(() => getOrderList()).then((res) => {
  //   //   console.log(res.data);
  //   //   setOrderList(res.data.patronOrderDetailsDtoList);
  //   //   setTotalPages(res.data.totalPages);
  //   // });
  // }, [currentPage]);

  const [showContactOrganizerModal, setShowContactOrganizerModal] =
    useState(false);
  const [contactOrganizerFormStep, setContactOrganizerFormStep] = useState(
    CONTACT_ORGANIZER_STEPS.SHOW_FAQ
  );
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [refundReason, setRefundReason] = useState('');
  const [orderRefundId, setOrderRefundId] = useState('');
  const [feeDetails, setFeeDetails] = useState(null);
  const [selectedTab, setSelectedTab] = useState('upcoming');

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // getUserList();
  };

  // State hook to reset the contact organizer from fields.
  const resetContactOrganizerForm = () => {
    setContactOrganizerFormStep(CONTACT_ORGANIZER_STEPS.SHOW_FAQ);
  };

  // This function handle the submission of the contact form
  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    setContactOrganizerFormStep(CONTACT_ORGANIZER_STEPS.SHOW_DETAILS);
  };

  // This function is used to handle the cancellation of the order
  const handleCancelOrder = () => {
    let orderCancelDTO = {
      eventId: selectedOrder.eventId,
      orderId: selectedOrder.orderId,
      refundReason: refundReason,
      fullRefund: false,
    };

    cancelOrder(orderCancelDTO)
      .then((res) => {
        console.log(res.data);
        setOrderRefundId(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(orderCancelDTO);
  };

  // This function is used to handle the open cancel order modal.
  const handleOpenCancelOrderModal = () => {
    setShowCancelOrderModal(true);

    if (!feeDetails) {
      getFeeDetails()
        .then((res) => {
          console.log(res.data);
          setFeeDetails(res.data);
        })
        .catch((err) => {
          console.log('ERR', err);
        });
    }
  };

  // This function is used to fetch the sub total price for the ticket.
  const getSubTotalPrice = (ticketDTO) => {
    const { chargeTax, taxPercentage, ticketPrice, ticketType } = ticketDTO;

    return (
      ticketPrice +
      (chargeTax
        ? (ticketPrice * taxPercentage) / 100
        : ticketPrice * TAX_PERCENTAGE) +
      ticketPrice * SERVICE_FEE_PERCENT
    );
  };

  // This function is used to get the total price for the ticket.
  const getTotalPrice = () => {
    let subtotal = getSubTotalPrice(selectedOrder.ticketDetails);
    let discount = selectedOrder.couponDiscount || 0;

    return subtotal - discount;
  };

  // This function is used to get the refund amount.
  const getRefundAmount = () => {
    let refund =
      getTotalPrice() - RESTOCKING_FEE - GATEWAY_FEE - APPLICATION_FEE;

    if (refund < 0) {
      refund = 0;
    }

    return refund;
  };

  // TODO: Add loading message when the fetching data is completed
  // if (loading)
  //   return (
  //     <div className="p-5 d-flex align-items-center justify-content-center">
  //       <h6 className="me-2">Loading...</h6>
  //       <Spinner animation="border" variant="secondary" />
  //     </div>
  //   );
  const EventList = () => {
    // const queryClient = useQueryClient();

    const {
      isLoading,
      isError,
      data: ordersResponse,
    } = useQuery({
      queryKey: ['orders'],
      queryFn: getOrderList,
    });

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error</div>;

    // Check if the response has the 'data' property containing orders
    const orders = ordersResponse?.data;
    console.log('orders', orders);

    // Ensure orders is an array before trying to map
    if (!Array.isArray(orders.data)) {
      return <div>No orders available</div>;
    }

    return (
      <>
        {orders?.data.map((order) => {
          return (
            <>
              <p>{order.eventName}</p>
              <p>{order.eventDate}</p>
              <p>{order.eventVenue}</p>
              <p>{order.eventLink}</p>
              <p>{order.orderDate}</p>
              <p>{order.organizerContact}</p>
            </>
          );
        })}
      </>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div className={styles.myOrders}>
        <div className={styles.myOrdersContainer}>
          <h2>Orders</h2>
          <div className={styles.searchContainer}>
            <input type="text" placeholder="Search" />
            <input type="date" />
          </div>
          <div
            className={
              selectedTab === 'upcoming'
                ? styles.eventTypes
                : `${styles.eventTypes} ${styles.pastSelected}`
            }
          >
            <button
              className={selectedTab === 'upcoming' && styles.active}
              onClick={() => setSelectedTab('upcoming')}
            >
              <span>Upcoming Events</span>
            </button>
            <button
              className={selectedTab === 'past' && styles.active}
              onClick={() => setSelectedTab('past')}
            >
              <span>Past Events</span>
            </button>
          </div>
          <div className={styles.eventsListContainer}>
            <div className={styles.eventsList}>
              <EventList />
            </div>
          </div>
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default MyOrders;
