import styles from './IconTextDemo.module.scss';

export default function IconTextDemo({ img, title, text }) {
  return (
    <div className={styles.iconTextDemo}>
      <div className={styles.icon}>
        <div className={styles.imgContainer}>
          <img src={img} alt="icon" />
        </div>
      </div>
      <div className={styles.textContainer}>
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
}
