import styles from './CustomRadioButton.module.scss';
import radioChecked from '../../assets/radioCheck.svg';

export default function CustomRadioButton({
  onChangeChecked,
  variant,
  btnValue,
  radioCurrentValue,
  label,
}) {
  const checked = radioCurrentValue === btnValue;
  const getClassName = () => {
    return `${styles.customRadioBtn} ${variant ? styles[variant] : ''} ${
      checked ? styles.checked : ''
    }`;
  };

  return (
    <span className={getClassName()}>
      <button
        type="button"
        value={btnValue}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onChangeChecked(btnValue);
        }}
      >
        {checked && <img src={radioChecked} alt="checked" />}
      </button>

      <span className={styles.label}>{label}</span>
    </span>
  );
}
