import { ACCESS_TOKEN, HEADERS } from '../constants';
import { getItem } from './LocalStorageUtils';
import { getFormDataObj } from './CommonUtils';
import axios from 'axios';
import qs from 'qs';

const API_BASE_URL = process.env.REACT_APP_PATRON_API;

/**
 * Function to retrieve the authentication header including the bearer token.
 *
 * @return {Object} An object containing the authentication header.
 */
const getPatronHeaders = () => {
  console.log('getPatronHeaders token', process.env.REACT_APP_PATRON_API_KEY);

  return {
    ...HEADERS,
    Authorization: `Bearer ${process.env.REACT_APP_PATRON_API_KEY}`,
  };
};

const baseHeaders = {
  ...getPatronHeaders(),
  // ...HEADERS,
};

/**
 * Function performs the login with provided data and captcha response.
 *
 * @param {Object} data - An object containing user data.
 * @param {string} captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const login = (data, captchaResponse) => {
  const headers = {
    ...baseHeaders,
    'captcha-response': captchaResponse,
  };
  return axios.post(API_BASE_URL + '/authenticate', data, {
    headers: headers,
  });
};

/**
 * Function performs the login with provided data and captcha response.
 *
 * @param {Object} data - An object containing user data.
 * @param {string} captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
export const login2 = (data, captchaResponse) => {
  console.log('login2');

  const headers = {
    ...baseHeaders,
    // 'captcha-response': captchaResponse,
  };
  return axios.post(`${API_BASE_URL}/authentication/login`, data, {
    withCredentials: true,
    headers: headers,
  });
};

/**
 * Function to register a new user with provided data and captcha response.
 *
 * @param {Object} data - An object containing user data.
 * @param {string} captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const register = (data, captchaResponse) => {
  const headers = {
    ...baseHeaders,
    // 'captcha-response': captchaResponse,
  };
  return axios.post(API_BASE_URL + '/authentication/register', data, {
    headers: headers,
  });
};

/**
 * Function to verify the email with provided code and authentication token.
 *
 * @param {string} code - A string representing the verification code.
 * @param {string} pendingAuthenticationToken - A string representing the pending authentication token.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const verifyemail = (code, pendingAuthenticationToken) => {
  return axios.get(`${API_BASE_URL}/authentication/verify-email?`, {
    params: {
      code: code,
      pendingAuthenticationToken: pendingAuthenticationToken,
    },
    headers: {
      ...baseHeaders,
    },
    withCredentials: true,
  });
};

// Function to send the reset password email.
/**
 * Function to send the reset password email.
 *
 * @param {Object} data - An object containing email and captchaResponse.
 * @param {string} data.email - A string representing the user's email.
 * @param {string} data.captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const sendResetPasswordEmail = (data) => {
  const LOGIN_HEADERS = {
    ...baseHeaders,
    'captcha-response': data.captchaResponse,
  };
  return axios.get(
    `${API_BASE_URL}/authentication/forgot-password?email=${data.email}`,
    {
      headers: LOGIN_HEADERS,
    }
  );
};

// DEPRECATED
// function to verify the token to reset the password.
// const verifyToken = (data) => {
//     return axios.post(API_BASE_URL + '/password/reset/token/verify', data, { headers: HEADERS })
// }

/**
 * Function to reset a new password.
 *
 * @param {Object} data - An object containing token and password.
 * @param {string} data.token - A string representing the reset token.
 * @param {string} data.password - A string representing the new password.
 * @param {string} captchaResponse - A string representing the captcha response.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const resetNewPassword = (data, captchaResponse) => {
  const LOGIN_HEADERS = {
    ...baseHeaders,
    'captcha-response': captchaResponse,
  };
  return axios.post(API_BASE_URL + '/reset-password', data, {
    headers: LOGIN_HEADERS,
  });
};

/**
 * Function to retrieve the list of event types.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventTypes = () => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/eventType/list`, { headers: headers });
};

/**
 * Function to retrieve the list of event genres.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventGenres = () => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/genre/list`, { headers: headers });
};

/**
 * Function to retrieve the location type.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getLocationTypes = () => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/locationtype/list`, { headers: headers });
};

/**
 * Function to get the event detail based on event ID.
 *
 * @param {string} eventId - The ID of the event.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventDetails = (eventId) => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/event/${eventId}`, {
    headers: headers,
  });
};

/**
 * Function to get the category detail of an event based on event ID.
 *
 * @param {boolean} isloggedIn - Indicates if the user is logged in.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getCategoryDetails = (isloggedIn) => {
  const headers = {
    ...baseHeaders,
  };

  return axios.get(API_BASE_URL + `/eventCategories/list`, {
    headers: headers,
  });
};

/**
 * Function to filter the event list.
 *
 * @param {Object} data - The data for filtering the event list.
 * @param {number} [page=1] - The page number of the event list.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const eventListFilter = (data, page = 1) => {
  console.log('bbbbb', data);
  const headers = {
    ...baseHeaders,
  };
  return axios.post(
    API_BASE_URL + `/event/list/filter?page=${page}&size=10`,
    data,
    { headers: headers }
  );
};

/**
 * Function to retrieve event list.
 *
 * @param {number} [page] - The page number of the event list.
 * @param {boolean} isloggedIn - Indicates if the user is logged in.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventList = (page, isloggedIn) => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(
    API_BASE_URL + `/get-event-list${page ? '?page=' + page : ''}`,
    { headers: headers }
  );
};

/**
 * Function to get list of customized event.
 *
 * @param {boolean} isloggedIn - Indicates if the user is logged in.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getEventListCustomized = (isloggedIn) => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/event/list/customized`, {
    headers: headers,
  });
};

/**
 * Function to retrieve the details of camera based on event ID.
 *
 * @param {string} eventId - The ID of the event.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getCameraDetails = (eventId) => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/promoter/event/cameras/${eventId}`, {
    headers: headers,
  });
};

/**
 * Function to retrieve user details.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getUserDetails = async () => {
  console.log('get user details');

  try {
    const userInfo = await axios.get(
      `${API_BASE_URL}/authentication/get-user-info`,
      {
        headers: { ...baseHeaders },
        withCredentials: true,
      }
    );
    return userInfo.data;
  } catch (error) {
    console.error('Failed to fetch user info', error);
    return null;
  }
};

const logoutUser = () => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(`${API_BASE_URL}/authentication/logout`, {
    headers: headers,
    withCredentials: true,
  });
};

const triggerGoogleAuth = () =>
  (window.location.href = `${process.env.REACT_APP_PATRON_API}/authentication/google-authentication-trigger`);
// return axios.get(
//   `${process.env.REACT_APP_PATRON_API}/authentication/google-authentication-trigger`,
//   {
//     headers: getAuthHeaders(),
//   }
// );

/**
 * Function to reset the stripe link.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const resetStripeLink = () => {
  const headers = {
    ...baseHeaders,
  };
  return axios.get(API_BASE_URL + `/reset-link`, { headers: headers });
};

/**
 * Function to update the contact information of the user.
 *
 * @param {Object} data - The data containing the contact information.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const updateContactInfo = (data) => {
  console.log(baseHeaders);
  let formData = getFormDataObj(data);
  return axios.put(API_BASE_URL + `/authentication/update-contact-info`, data, {
    headers: { ...baseHeaders },
    withCredentials: true,
  });
};

/**
 * Function to retrieve the methods to make a payment.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const fetchPaymentMethods = () => {
  return axios.get(API_BASE_URL + `/getPaymentMethods`, {
    headers: { ...baseHeaders },
  });
};

/**
 * Function to pay with already added payment method.
 *
 * @param {Object} paymentBody - The payment body containing the payment details.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const payWithExistingMethod = (paymentBody) => {
  return axios.post(API_BASE_URL + `/payment`, paymentBody, {
    headers: { ...baseHeaders },
  });
};

/**
 * Saves the billing information for a specific event.
 *
 * @param {number} eventId - The ID of the event.
 * @param {Object} billingDetails - The billing details to be saved.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const saveBillingInfo = (eventId, billingDetails) => {
  return axios.post(
    API_BASE_URL + `/checkout/billingInfo/${eventId}`,
    billingDetails,
    { headers: { ...baseHeaders } }
  );
};

/**
 * Validates a coupon for a specific event.
 *
 * @param {number} eventId - The ID of the event.
 * @param {string} couponCode - The coupon code to be validated.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const validateCoupon = (eventId, couponCode) => {
  return axios.post(
    API_BASE_URL + `/coupon/validate/${eventId}?coupon=${couponCode}`,
    {},
    { headers: { ...baseHeaders } }
  );
};

/**
 * Retrieves the list of orders made by the user.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getOrderList = () => {
  const prom = axios.get(API_BASE_URL + `/order/get-orders`, {
    headers: { ...baseHeaders },
  });

  console.log('get order list promise: ', prom);

  return prom;
};

/**
 * Cancels an order made by the user.
 *
 * @param {Object} orderDetails - The details of the order to be cancelled.
 * @return {Promise} A promise that resolves to the response from the server.
 */
const cancelOrder = (orderDetails) => {
  return axios.post(API_BASE_URL + `/refund-customer`, orderDetails, {
    headers: { ...baseHeaders },
  });
};

/**
 * Retrieves the details of the fee charged for an order.
 *
 * @return {Promise} A promise that resolves to the response from the server.
 */
const getFeeDetails = () => {
  return axios.get(API_BASE_URL + `/order/fee/details`, {
    headers: { ...baseHeaders },
  });
};

const googleCallbackFetchData = async (code) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PATRON_API}/authentication/google-callback`,
      {
        params: {
          code: code,
        },
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          ...baseHeaders,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Failed to fetch user info', error);
  }
};

const sendContactMessage = async (data) => {
  try {
    const contactResponse = await axios.post(
      `${process.env.REACT_APP_PATRON_API}/contact/contact-us-message`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          ...baseHeaders,
        },
      }
    );

    return contactResponse;
  } catch (error) {
    console.error('Failed to fetch user info', error);
  }
};

const getContentForPage = async (page, language) => {
  try {
    const contentResponse = await axios.get(
      `${process.env.REACT_APP_PATRON_API}/content/get-content-for-page/${page}/${language}`,
      {
        headers: {
          'Content-Type': 'application/json',
          ...baseHeaders,
        },
      }
    );
    console.log('getContentForPage: ', contentResponse.data);
  } catch (error) {
    console.log('Error on getContentForPage: ', error);
  }
};

// Checks if the user credentials are valid
// Receives an object with the user's email and password
const checkUserCredentials = async (data) => {
  console.log('checkUserCredentials: ', data);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PATRON_API}/authentication/check-user-credentials`,
      data,
      {
        // withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          ...baseHeaders,
        },
      }
    );
    console.log('response.data on checkUserCredentials: ', response);

    return { success: true };
  } catch (error) {
    console.error('Failed to fetch user info', error);
    return { success: false, message: error.response.data.message };
  }
};

// Close the WorkOS account and change the DB to turn the user into Inactive.
// Receives an object with the user's email and the reason for closing.
const closePatronAccount = async (data) => {
  try {
    const response = await axios.delete(
      API_BASE_URL + `/authentication/close-account`,

      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          ...baseHeaders,
        },
        data: data,
      }
    );
    return response;
  } catch (error) {
    console.log('Error on closePatronAccount: ', error);
  }
  return;
};

// Export all API Functions.
export {
  login,
  register,
  sendResetPasswordEmail,
  verifyemail,
  resetNewPassword,
  triggerGoogleAuth,
  getEventTypes,
  getEventGenres,
  getLocationTypes,
  getEventDetails,
  getEventList,
  getEventListCustomized,
  getCameraDetails,
  getUserDetails,
  logoutUser,
  resetStripeLink,
  updateContactInfo,
  fetchPaymentMethods,
  payWithExistingMethod,
  validateCoupon,
  saveBillingInfo,
  getOrderList,
  cancelOrder,
  getFeeDetails,
  getCategoryDetails, //Category Filtering
  eventListFilter, // Filtering events
  googleCallbackFetchData,
  sendContactMessage,
  getContentForPage,
  checkUserCredentials,
  closePatronAccount,
};
