import { URL_REGEX, INT_REGEX } from '../../../constants';

/**
 * Function to validate the address field.
 *
 * @param {Object} dto - The DTO object containing the address information.
 * @param {string} dto.addressLine1 - The first line of the address.
 * @param {string} dto.city - The city of the address.
 * @return {Object} An error object containing the validation errors.
 */
const validateAddress = (dto) => {
  let error = {};

  if (!dto.addressLine1 || dto.addressLine1.trim().length === 0) {
    error['addressLine1'] = 'This field is required';
  }

  if (!dto.city || dto.city.trim().length === 0) {
    error['city'] = 'Please enter the city';
  }

  if (!dto.postalCode || dto.postalCode.trim().length === 0) {
    error['postalCode'] = 'Please enter your postal code';
  }

  return error;
};

/**
 * Function to validate the contact information fields.
 *
 * @param {Object} state - The state object containing the contact information.
 * @param {string} state.firstName - The first name of the contact.
 * @param {string} state.lastName - The last name of the contact.
 * @param {string} state.email - The email of the contact.
 * @param {string} state.phoneNumber - The phone number of the contact.
 * @param {Array} state.addressDetailsList - The list of address details.
 * @return {Object} An error object containing the validation errors.
 */
const validateContactInfo = (contactInfo) => {
  let error = {};

  if (!contactInfo.firstName || contactInfo.firstName.trim().length === 0) {
    error['firstName'] = 'Please enter your first name';
  }

  if (!contactInfo.lastName || contactInfo.lastName.trim().length === 0) {
    error['lastName'] = 'Please enter your last name';
  }

  // if (!state.email || state.email.trim().length === 0) {
  //   error['email'] = 'Please enter contact email!';
  // }

  if (contactInfo.phoneNumber && contactInfo.phoneNumber?.trim().length !== 0) {
    if (
      isNaN(contactInfo.phoneNumber) ||
      INT_REGEX.test(contactInfo.phoneNumber) === false
    ) {
      error['phoneNumber'] = 'Please provide a valid number!';
    }
  }

  // for (let i = 0; i < contactInfo.addressDetailsList.length; i++) {
  //   const addrrDTO = contactInfo.addressDetailsList[i];
  //   let addErr = validateAddress(addrrDTO);

  //   if (Object.keys(addErr).length) {
  //     error[addrrDTO.addressType] = { ...addErr };
  //   }
  // }

  return error;
};

export default validateContactInfo;
