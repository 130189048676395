import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
  faChevronDown,
  faChevronUp,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';

/**
 * Render an event list card.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.event - The event object.
 * @param {number} props.index - The index of the event.
 * @return {JSX.Element} The rendered event list card.
 */
const EventListCard = ({ event, index }) => {
  /**
   * The default thumbnail URL used when no thumbnail is provided by the event.
   *
   * @type {string}
   */
  const defaultThumbnail =
    'https://via.placeholder.com/150/FF5733/FFFFFF/?text=Thumbnail';

  /**
   * useState hook to manage the state of the showDescription variable.
   *
   * @return {Array} An array with two elements: the current state of showDescription, and a function to update it.
   */
  const [showDescription, setShowDescription] = useState(false);

  /**
   * Event handler function to toggle the showDescription state.
   *
   * @param {Event} e - The event object.
   * @return {void} This function does not return anything.
   */
  const handleShowDescription = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowDescription(!showDescription);
  };

  return (
    <ul key={index} className="list-unstyled p-0 mb-4">
      <li>
        <Link to={`/events/${event.eventId}`}>
          <Card className="bg-header">
            <Card.Body className="px-5 py-4">
              <Row>
                <Col lg="2" xs="4" className="d-flex align-items-center">
                  {event.thumbnailUrl && (
                    <Card.Img
                      className="img-fluid"
                      src={event.thumbnailUrl}
                      alt={event.eventTitle}
                    />
                  )}
                  {!event.thumbnailUrl && (
                    <Card.Img
                      className="img-fluid"
                      src={defaultThumbnail}
                      alt={event.eventTitle}
                    />
                  )}
                </Col>
                <Col lg="7" xs="8" className="d-flex align-items-center">
                  <div className="d-flex flex-column h-100 text-default">
                    <Card.Title as="h3" className="fw-bold text-primary">
                      {event.eventTitle}
                    </Card.Title>
                    <Card.Text as="h4">
                      {moment(event.eventStartDate).format('MMM DD')}
                    </Card.Text>
                    <Card.Text as="h5">{event.summary}</Card.Text>
                  </div>
                </Col>
                <Col lg="3" xs="12" className="mt-4 mt-lg-0">
                  {index % 2 == 0 && (
                    <>
                      <Button
                        style={{ minWidth: '225px' }}
                        as={Link}
                        to={`/events/${event.eventId}?showTickets=true`}
                        variant="primary"
                        className="rounded-pill d-flex align-items-center px-4 py-3"
                      >
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          className="d-none d-lg-block"
                        />
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          size="2x"
                          className="d-block d-lg-none ms-5"
                        />
                        <div className="text-white text-center flex-grow-1">
                          <h5 className="mb-0"> View Tickets </h5>
                          <div className="fs-12">Start from $20</div>
                        </div>
                      </Button>
                      <p className="text-default mt-2 text-center">
                        120 People shared this event
                      </p>
                    </>
                  )}

                  {index % 2 != 0 && (
                    <>
                      <Button
                        style={{ minWidth: '225px' }}
                        to={`/events/${event.eventId}/performances?showTickets=false`}
                        as={Link}
                        variant="primary"
                        className="rounded-pill d-flex align-items-center px-4 py-3"
                      >
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          className="d-none d-lg-block"
                        />
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          size="2x"
                          className="d-block d-lg-none ms-5"
                        />
                        <div className="text-white text-center flex-grow-1">
                          <h5 className="mb-0">View Performances</h5>
                          <div className="fs-12">Start from $20</div>
                        </div>
                      </Button>
                      <p className="text-default mt-2 text-center">
                        120 People shared this event
                      </p>
                    </>
                  )}
                </Col>
              </Row>

              <div>
                {showDescription && (
                  <>
                    {/* event description */}
                    <div className="text-default mt-4">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into ele ctronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsu
                    </div>
                  </>
                )}

                <div className="d-flex justify-content-center">
                  <Button
                    variant="link"
                    onClick={(e) => handleShowDescription(e)}
                  >
                    <FontAwesomeIcon
                      size="2x"
                      className="text-default"
                      title="event Information"
                      icon={showDescription ? faChevronUp : faChevronDown}
                    />
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Link>
      </li>
    </ul>
  );
};

export default EventListCard;
