const keys = require('../config/keys');
// const storageUtils =  require("../utils/LocalStorageUtils");

/**
 * Global constant for the Google API key used by the React component.
 * @type {string}
 */
export const REACT_APP_GOOGLE_API_KEY = keys.reactAppGoogleApiKey;

/**
 * Export the global constant Google Access Token for the React component.
 * @type {string}
 */
export const REACT_APP_GOOGLE_ACCESS_TOKEN = keys.reactAppGoogleAccessToken;

/**
 * Export the Google Calendar ID used by the React app.
 * @type {string}
 */
export const REACT_APP_CALENDAR_ID = keys.reactAppGoogleCalendarId;

/**
 * Export the base URL for the API calls.
 * @type {string}
 */
// export const API_BASE_URL = keys.apiBaseUrl;
// export const API_BASE_URL = 'http://localhost:8787';

/**
 * Export the Base URL for the Application.
 * @type {string}
 */
export const BASE_URL = keys.baseUrl;

/**
 * Export the Access Token used for the Authorization.
 * @type {string}
 */
export const ACCESS_TOKEN = 'idToken';

/**
 * Global constant used to store the information of the logged in USER.
 * @type {string}
 */
export const LOGGED_IN_USER = 'loggedInUser';

/**
 * Export the OAuth 2.0 Redirect URI used for the Authorization.
 * @type {string}
 */
export const OAUTH2_REDIRECT_URI = keys.redirectUri;

/**
 * Export the Google Authorization URL.
 * @type {string}
 */
export const GOOGLE_AUTH_URL = OAUTH2_REDIRECT_URI;

/**
 * Global constant for the Google Client ID.
 * @type {string}
 */
export const CLIENT_ID = keys.googleClientId;

/**
 * Global constant for the Google OAuth API key.
 * @type {string}
 */
export const API_KEY = keys.googleOAuthApiKey;

/**
 * Export the Google API Discovery Docs Array for the application.
 * @type {Array<string>}
 */
export const DISCOVERY_DOCS = keys.gapiDiscoverDocs;

/**
 * Export the Google API Scopes for the Application.
 * @type {string}
 */
export const SCOPES = keys.gapiScopes;

/**
 * Export the Stripe Publish Key used for the client-side interactions.
 * @type {string}
 */
export const STRIPE_PUBLISH_KEY = keys.stripePublishKey;

//  export const BASE_URL = 'http://44.212.37.237:8080/tw';
//export const BASE_URL = 'https://ticket-window-backend.herokuapp.com'; // for testing
// export const BASE_URL = 'http://localhost:8080';
// export const BASE_URL = 'https://ticket-window-backend.herokuapp.com'; // for testing

// export const BASE_URL = 'https://3e65-76-64-82-95.ngrok.io';
//export const BASE_URL = 'http://44.212.37.237:8080/tw';
// export const BASE_URL = 'https://349d-142-198-107-172.ngrok.io';

/**
 * Exports the HTTP headers that are commonly used to make JSON-based
 * API requests.
 * @constant
 * @type {Object}
 * @property {string} "Content-Type" - The type of content being sent in the
 * request body.
 */
export const HEADERS = {
  'Content-Type': 'application/json',
};

// const getAuthHeaders = () => {
//     return {
//         "Content-Type": "application/json",
//         "Authorization": `Bearer ${getItem(ACCESS_TOKEN)}`
//     }
// }

// export const AUTH_HEADERS = {
//     ...getAuthHeaders()
// }

/**
 * Global constant used for React Application Site Key.
 * @type {string}
 */
export const SITE_KEY = keys.reactAppSiteKey;

/**
 * Namespace for local storage keys constants.
 * @namespace
 */
// local storage keys constants

/**
 * Global constant for the Event Banner Image.
 * @type {string}
 */
export const EVENT_BANNER_IMG = 'eventBannerImage';

/**
 * Global constant used to store the array of image types.
 * @type {Array<string>}
 */
export const IMAGE_TYPES = [
  'image/png',
  'image/gif',
  'image/bmp',
  'image/jpeg',
];

/**
 * Global constant used for the Maximun Event Image Count.
 * @constant {number}
 */
export const MAX_EVENT_IMG_COUNT = 3;

/**
 * Global constant for Maximun Camera Count.
 * @constant {number}
 */
export const MAX_CAMERA_COUNT = 5;

// Global constant for the Tax Percentage
/**
 * Global constant for the Tax Percentage.
 *
 * @constant {number}
 * @default 0.13
 * @description The tax percentage added GST/HST for Ontario.
 */
export const TAX_PERCENTAGE = 0.13;

/**
 * Global constant for the service fee percentage.
 *
 * @constant {number}
 * @default 0.05
 * @description The percentage of the service fee.
 */
export const SERVICE_FEE_PERCENT = 0.05;

/**
 * Global constant for the restocking fee.
 *
 * @constant {number}
 * @default 25
 * @description The restocking fee.
 */
export const RESTOCKING_FEE = 25;

/**
 * Global constant for the application fee.
 *
 * @constant {number}
 * @default 7
 * @description The application fee.
 */
export const APPLICATION_FEE = 7;

/**
 * Global constant for the gateway fee.
 *
 * @constant {number}
 * @default 3.5
 * @description The gateway fee.
 */
export const GATEWAY_FEE = 3.5;

/**
 * @enum {string}
 * @readonly
 * @description Enumeration indicate the type of the user Promoter or Patron
 */
export const USER_TYPE = Object.freeze({
  /**
   * @type {string}
   * @description Indicates the user is a Promoter
   */
  PROMOTER: 'PROMOTER',
  /**
   * @type {string}
   * @description Indicates the user is a Patron
   */
  PATRON: 'PATRON',
});

/**
 * @enum {string}
 * @readonly
 * @description Enumeration for the User Roles
 */
export const USER_ROLES = Object.freeze({
  /**
   * @type {string}
   * @description Indicates the user role is a User
   */
  ROLE_USER: 'ROLE_USER',
  /**
   * @type {string}
   * @description Indicates the user role is a Promoter
   */
  ROLE_PROMOTER: 'ROLE_PROMOTER',
  /**
   * @type {string}
   * @description Indicates the user role is a Super Admin
   */
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
});

/**
 * @enum {string}
 * @readonly
 * @description Enumeration for the Social Media URL's
 */
export const SOCIAL_MEDIA_URLS = Object.freeze({
  /**
   * @type {string}
   * @description URL for Twitter
   */
  TWITTER: 'https://twitter.com/TicketWindowInc',
  /**
   * @type {string}
   * @description URL for Facebook
   */
  FACEBOOK: 'https://www.facebook.com/ticketwindowinc/',
  /**
   * @type {string}
   * @description URL for YouTube
   */
  YOUTUBE: 'https://www.youtube.com/user/TIcketWindowTV',
});

/**
 * @enum {string}
 * @readonly
 * @description Enumeration for the event steps.
 */
export const EVENT_STEPS = Object.freeze({
  BASIC_DETAILS: 'BASIC_DETAILS', // we don't have any path for basic info step as it lies at index
  MAIN_DETAILS: 'MAIN_DETAILS',
  IN_PERSON_DETAILS: 'IN_PERSON_DETAILS',
  STREAMING_DETAILS: 'STREAMING_DETAILS',
  IN_PERSON_TICKET_DETAILS: 'IN_PERSON_TICKET_DETAILS',
  EVENT_CHOICE: 'EVENT_CHOICE',
  TICKET_DETAILS: 'TICKET_DETAILS',
  FINAL_DETAILS: 'FINAL_DETAILS',
});

/**
 * @enum {string}
 * @readonly
 * @description Enumeration for the Ticket Type
 */
export const TICKET_TYPE = Object.freeze({
  PAID: 'PAID',
  FREE: 'FREE',
});

// export const COUPON_TYPE = Object.freeze({
//     FIXED_AMOUNT: 'PAID',
//     PERCENT_DISCOUNT: 'FREE'
// })

/**
 * @enum {string}
 * @readonly
 * @description Enumeration for the type of ticket location.
 */
export const TICKET_LOCATION_TYPE = Object.freeze({
  LIVESTREAM: 'LIVESTREAM',
  IN_PERSON: 'IN_PERSON',
  HYBRID: 'HYBRID',
});

/**
 * @enum {string}
 * @readonly
 * @description Enumeration for the availability of the event.
 */
export const EVENT_AVAILABILITY = Object.freeze({
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
});

/**
 * @enum {string}
 * @readonly
 * @description Enumeration for the status of the event.
 */
export const EVENT_STATUS = Object.freeze({
  IN_PROGRESS: 'IN PROGRESS',
  IN_REVIEW: 'IN REVIEW',
  DECLINED: 'DECLINED',
  APPROVED: 'APPROVED',
});

/**
 * @enum {string}
 * @readonly
 * @description Enumeration for the status of the user.
 */
export const USER_STATUS = Object.freeze({
  REJECTED: 'REJECTED',
  IN_REVIEW: 'IN REVIEW',
  APPROVED: 'APPROVED',
});

/**
 * @enum {string}
 * @readonly
 * @description Enumeration for the actions of the channel.
 */
export const CHANNEL_ACTIONS = Object.freeze({
  START: 'START',
  STARTING: 'STARTING',
  RUNNING: 'RUNNING',
  STOP: 'STOP',
  DELETE: 'DELETE',
});

/**
 * @constant {string}
 * @readonly
 * @description Global constant for the text of the Cancel Order ToolTip.
 */
export const CANCEL_ORDER_TOOLTIP =
  'You can cancel your order 48 hours before event start time. Service and Restocking fees are non-refundable';

/**
 * @description
 * List all regex for consistent validations.
 */

/**
 * @constant {RegExp}
 * @readonly
 * @description Global constant for the Email regular expression.
 *
 * This regular expression matches a valid email address. It supports the following formats:
 * - `user@example.com`
 * - `first.last@example.com`
 * - `email+label@example.com`
 * - `"email"@example.com`
 * - `1234567890@example.com`
 * - `!def!xyz%abc@example.com`
 * - `very.unusual.@.unusual.com@example.com`
 * - `very.-unusual.@.unusual.com@example.com`
 * - `admin@mailserver1`
 *
 * This regular expression does not support the following formats:
 * - `Abc.example.com`
 * - `A@b@c@example.com`
 * - `a"b(c)d,e:f;g<h>i[j\k]l@example.com`
 * - `just"not"right@example.com`
 * - `this is"not\allowed@example.com`
 * - `this still''not\\allowed@example.com`
 *
 * @see {@link https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address|HTML Living Standard}
 */
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Global constant for the URL regular expression
/**
 * @constant {RegExp}
 * @readonly
 * @description Global constant for the URL regular expression.
 *
 * This regular expression matches a valid URL. It supports the following formats:
 * - `https://www.example.com`
 * - `http://www.example.com`
 * - `www.example.com`
 * - `example.com`
 * - `localhost`
 * - `localhost:3000`
 * - `example.com/path`
 * - `example.com/path/`
 * - `example.com/path?query=param`
 * - `example.com/path#anchor`
 * - `example.com/path?query=param#anchor`
 *
 * This regular expression does not support the following formats:
 * - `example.com/path.png`
 * - `example.com/path.html?query=param#anchor`
 * - `example.com:8080`
 * - `example.com:8080/path`
 * - `example.com:8080/path/`
 * - `example.com:8080/path?query=param#anchor`
 *
 * @see {@link https://www.ietf.org/rfc/rfc3986.txt|RFC 3986}
 */
export const URL_REGEX =
  /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

// Global constant for the Integers regular expression
/**
 * @constant {RegExp}
 * @readonly
 * @description Global constant for the Integers regular expression.
 *
 * This regular expression matches a valid integer. It supports both positive and negative numbers.
 * It does not match decimal numbers.
 *
 * @example
 * const intRegex = new RegExp(INT_REGEX);
 * intRegex.test('123'); // true
 * intRegex.test('-123'); // true
 * intRegex.test('123.45'); // false
 */
export const INT_REGEX = /^-?[0-9]+$/;

// Global constant for the Coupon Code regular expression
/**
 * @constant {RegExp}
 * @readonly
 * @description Global constant for the Coupon Code regular expression.
 *
 * This regular expression matches a coupon code that consists of numbers and letters only.
 * It does not match any other characters.
 *
 * @example
 * const couponCodeRegex = new RegExp(COUPON_CODE_REGEX);
 * couponCodeRegex.test('ABC123'); // true
 * couponCodeRegex.test('abc123'); // true
 * couponCodeRegex.test('ABC123!'); // false
 * couponCodeRegex.test('123abc'); // true
 * couponCodeRegex.test('123ABC'); // true
 * couponCodeRegex.test('123abc!'); // false
 * couponCodeRegex.test('123ABC!'); // false
 * couponCodeRegex.test('123'); // true
 * couponCodeRegex.test('1234567890'); // true
 * couponCodeRegex.test('abcdefghijklmnopqrstuvwxyz'); // true
 * couponCodeRegex.test('ABCDEFGHIJKLMNOPQRSTUVWXYZ'); // true
 * couponCodeRegex.test('abcdefghijklmnopqrstuvwxyz1234567890'); // true
 * couponCodeRegex.test('ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'); // true
 * couponCodeRegex.test('abcdefghijklmnopqrstuvwxyz!'); // false
 * couponCodeRegex.test('ABCDEFGHIJKLMNOPQRSTUVWXYZ!'); // false
 * couponCodeRegex.test('!abcdefghijklmnopqrstuvwxyz'); // false
 * couponCodeRegex.test('!ABCDEFGHIJKLMNOPQRSTUVWXYZ'); // false
 * couponCodeRegex.test('!abcdefghijklmnopqrstuvwxyz1234567890'); // false
 * couponCodeRegex.test('!ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'); // false
 */
export const COUPON_CODE_REGEX = /^[A-Za-z0-9]*$/;
