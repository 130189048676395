import React, { useState, useCallback } from 'react';
import styles from './ImagePicker.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../../utils/imageUtils';

function ImagePicker({ image, onImageChange }) {
  const [selectedImage, setSelectedImage] = useState(image || null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropArea, setCropArea] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl); // Set the image in local state for cropping
      setShowCropper(true); // Show the cropper when a new image is selected
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropArea(croppedAreaPixels);
  }, []);

  const handleCropConfirm = async () => {
    try {
      const croppedImageData = await getCroppedImg(selectedImage, cropArea); // Get the cropped image data
      setCroppedImage(croppedImageData); // Set the final cropped image
      setShowCropper(false); // Hide the cropper
      onImageChange(croppedImageData); // Pass the cropped image back to the parent
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={styles.imagePickerContainer}>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        // style={{ display: 'none' }} // Hide the default file input
        id="filePicker"
        className={styles.fileInput}
      />
      <label htmlFor="filePicker" className={styles.imageLabel}>
        <div className={styles.imageCircle}>
          <img
            src={croppedImage || image || '/path-to-default-avatar.png'} // Use a default avatar if no image
            alt="Profile"
            className={styles.profileImage}
          />
          <div className={styles.editOverlay}>
            <FontAwesomeIcon icon={faPen} />
            <span>Edit</span>
          </div>
        </div>
      </label>

      {showCropper && (
        <div className={styles.cropperContainer}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1} // 1:1 aspect ratio
            cropShape="round"
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          <button
            type="button"
            onClick={handleCropConfirm}
            className={styles.cropConfirmBtn}
          >
            Confirm Crop
          </button>
        </div>
      )}
    </div>
  );
}

export default ImagePicker;
