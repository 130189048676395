import React from 'react';
import { Toast } from 'react-bootstrap';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';

/**
 * React functional component that displays a toast message with a success or error based on the props.
 *
 * @param {Object} props - The properties object containing the following properties:
 *   @property {boolean} show - Indicates whether the toast message should be displayed or not.
 *   @property {function} onClose - The function to handle the close event of the toast message.
 *   @property {boolean} success - Indicates whether the toast message is a success or error message.
 *   @property {string} msg - The message to be displayed in the toast message.
 * @return {JSX.Element} The rendered toast message component.
 */
const ToastMessage = (props) => {
  // http error codes goes here

  return (
    <ToastContainer position="top-center" className="p-3">
      <Toast
        show={props.show}
        onClose={props.onClose}
        autohide={true}
        delay={3000}
      >
        <Toast.Body
          className={`${
            props.success
              ? 'bg-success border-success'
              : 'bg-danger border-danger'
          } text-white`}
        >
          <FontAwesomeIcon
            icon={props.success ? faCheckCircle : faTriangleExclamation}
            className="me-2"
          />
          {props.msg}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastMessage;
