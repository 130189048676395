import React, { useState, useEffect } from 'react';
import moment from 'moment';

/**
 * Counter is a React functional component that manages the functionality of a timer based on the start and end time.
 *
 * @param {Object} props - The properties object containing the startTime and endTime.
 * @param {number} props.startTime - The start time of the timer in Unix timestamp format.
 * @param {number} props.endTime - The end time of the timer in Unix timestamp format.
 * @return {JSX.Element} - The rendered Counter component.
 */
const Counter = ({ startTime, endTime }) => {
  /**
   * A hook that provides the state for the countdown string and a function to set it.
   *
   * @return {Array} An array containing the countdown string (string) and a function to set it (Function).
   */
  const [countDownString, setCountdownString] = useState('');

  /**
   * useEffect is a built-in React Hook that allows you to perform side effects in function components.
   *
   * @param {Function} effect - The function that contains the code you want to run after each render.
   * @param {Array} dependencies - An array of dependencies on which the effect depends. If any of the dependencies change, the effect will be re-run. If the array is empty, the effect will run once after the initial render, and not update again.
   * @return {void} This hook does not return anything.
   */
  useEffect(() => {
    // let diffTime = startTime - moment(new Date().getTime()).unix();
    let diffTime = endTime - moment(new Date().getTime()).unix();
    let duration = moment.duration(diffTime * 1000, 'milliseconds');
    let MINUTE_MS = 1000;

    const interval = setInterval(function () {
      duration = moment.duration(duration - MINUTE_MS, 'milliseconds');
      setCountdownString(
        ('0' + duration.hours()).slice(-2) +
          ':' +
          ('0' + duration.minutes()).slice(-2) +
          ':' +
          ('0' + duration.seconds()).slice(-2)
      );
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [countDownString]);

  return <span>{countDownString}</span>;
};

export default Counter;
