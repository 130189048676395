import styles from './ChangeCredentialsPopupContent.module.scss';
import FormInput from '../FormInput/FormInput';
import { useState } from 'react';
import CustomButton from '../CustomButton/CustomButton';
import { useModal } from '../../context/useModal';
import { validateEmail } from '../../utils/authValidationUtils';
import { checkUserCredentials } from '../../utils/APIUtils';

export default function ChangeCredentialsPopupContent({
  onCredentialsValidation,
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  const { closeModal } = useModal();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(email, password);
    const emailValidationError = validateEmail(email);
    if (emailValidationError) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          email: emailValidationError,
        };
      });
    } else {
      // TODO: Send creadential to the Backend and validate it
      const credentialsValidated = await checkUserCredentials({
        email: email,
        password: password,
      });

      // ******* I AM HERE ********
    }
    // TODO: Implement the rest of the logic

    // If the response is ok
    onCredentialsValidation(true);
    closeModal();
    // If the response is not ok
    // setErrors({
    //   email: 'Invalid credentials',
    // });
  };
  return (
    <form
      className={styles.changeCredentialsPopupContent}
      onSubmit={handleSubmit}
    >
      <h2 className={styles.title}>Please insert your current info</h2>
      <div className={styles.formGroup}>
        <FormInput
          label="Email Address"
          ariaLabel="Email Address"
          placeholder="Email"
          type="email"
          name="email"
          value={email}
          labelColor="semiDark"
          onInputChange={setEmail}
          error={errors.email}
          onClearError={() => setErrors({})}
        />
        <FormInput
          label="Password"
          ariaLabel="Password"
          placeholder="********"
          type="password"
          name="password"
          value={password}
          labelColor="semiDark"
          onInputChange={setPassword}
          minLength={8}
        />
      </div>
      <CustomButton title="Continue" type="submit" />
    </form>
  );
}
